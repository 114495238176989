import { imageSize_file, imageSize_video } from "../Config";

const convertImageSize_File = Math.round(imageSize_file / 1048576);
const convertImageSize_Video = Math.round(imageSize_video / 1048576);

export const SELECT_OFFICE_FORM_ABOVE_LIST = "Select office from above list ";
export const THERE_IS_NO_OFFICE_FOR_THIS_USER =
  "There is no office for this user";
export const CHOOSE_OFFICE_DOCTOR = "Select office and doctor ";

export const CHOOSE_OFFICE = "Please select office ";
export const CHOOSE_DOCTOR = "Please select doctor";
export const ENTER_DOCTOR_DETAIL = "Please enter doctor detail";
export const DOCTOR_ID_NOT_FOUND = "Doctor Id Not Found";
export const OFFICE_ID_NOT_FOUND = "Office Id Not Found";
export const ENTER_OLD_PASSWORD = "please enter your old password";
export const ENTER_NEW_PASSWORD = "Please enter new password";
export const PASSWORD_DOES_NOT_MATCH = "Password does not match";

export const SELECT_RELATIONSHIP = "Please Select RelationShip";
export const ENTER_POLICY_NUMBER = "Please enter policy-number";
export const MATCH_PASSWORD =
  "Password and confirm-password should have same value";
export const PHONE_NUMBER = "Phone number should be in 10 digits only";
export const AT_LEAST_ONE_OFFICE = "You have to Add atleast one Office.";
export const EMPTY_FIELD = "Please fill all the mandatory field";
export const ENTER_FNAME = "Please enter first name";
export const ENTER_LNAME = "Please enter last name";
export const ENTER_SPECIALTY = "Please select specialty";
export const ENTER_CPTGROUP = "Please select CPT Group";
export const ENTER_CPTGROUP_NAME = "Please enter CPT Group";
export const ENTER_PAYER = "Please select payer";
export const ENTER_PHONE = "Please enter phone number";
export const ENTER_STATE = "Please select state";
export const ENTER_CITY = "Please select city";
export const ENTER_CITY_NAME = "Please enter city";
export const ENTER_NPI1 = "Please enter npi1";

export const MAX_LENGTH = 20;
export const ENTER_NPI_LESS_THAN_10 = `Please enter npi less than ${MAX_LENGTH} digits`;
export const ENTER_SSN_LESS_THAN_10 = `Please enter ssn less than ${MAX_LENGTH} digits`;
export const ENTER_TAX_ID_LESS_THAN_10 = `Please enter tax Id less than ${MAX_LENGTH} digits`;
export const ENTER_PTAN_LESS_THAN_10 = `Please enter PTAN number less than ${MAX_LENGTH} digits`;
export const ENTER_FEE_LESS_THAN_10 = `Please enter fee less than ${MAX_LENGTH} digits`;

export const ENTER_DOB = "Please select birth-date";
export const ENTER_DOS = "Please select service-date";
export const ENTER_GENDER = "Please select gender";
export const ENTER_EMAIL = "Please enter email";
export const SELECT_ACCESSLEVEL = "Please Select Access Level";
export const SELECT_STATUS = "Please Select Status";
export const SELECT_AGREEMENT = "Please Select Agreement";
export const ENTER_PASSWORD = "Please enter password";
export const ENTER_CONFIRMPASSWORD = "Please enter confirm password";
export const PASSWORDANDCONFIRMPASSWORD =
  "Password and Confirm Password must be same";
export const ENTER_ZIP = "Please enter zip";
export const ENTER_OFFICE_NAME = "Please enter office name";
export const ENTER_ADDRESS = "Please enter address";
export const ENTER_MEDICARE_PATIENT_ID = "Please Enter Medicare Patient Id";
export const UPLOAD_IMAGE = "Please Upload Document";
// export const EXCEED_UPLOAD_SIZE_2M = "Please Upload a file within a size of 2M";
export const EXCEED_UPLOAD_SIZE_MAX_FILE = `Please Upload a file within a size of ${convertImageSize_File} M. Please send the image file to support@billwithus.com with the patient name.`;
export const EXCEED_UPLOAD_SIZE_MAX_VIDEO = `Please Upload a file within a size of ${convertImageSize_Video} M. Please send the image file to support@billwithus.com with the patient name.`;

export const AGE_MORE_THAN_18 = "Age should be more than 18 years";

export const PASSWORD_ERROR_TEXT =
  " *At least 8 characters : Uppercase, Lowercase, Numeric, or Special Characters.";

export const REJECT_MESSAGE = "Status will be changed to rejected";
export const APPROVE_MESSAGE = "Status will be changed to active";
export const DELETE_MESSAGE = "Are you sure want to delete this record?";
export const DELETE_DOCTOR_MESSAGE = "Are you sure want to delete this doctor?";
export const DELETE_EOB_MESSAGE = "Are you sure want to delete this E.O.B?";
export const DELETE_EOB_DOCUMENT_MESSAGE =
  "Are you sure want to delete this E.O.B document?";
export const DELETE_RECEIPT_MESSAGE =
  "Are you sure want to delete this Receipt?";
export const DELETE_RECEIPT_DOCUMENT_MESSAGE =
  "Are you sure want to delete this Receipt document?";
export const DELETE_TRIAL_MESSAGE = "Are you sure want to delete this trial?";
export const DELETE_EMPLOYEE_MESSAGE =
  "Are you sure want to delete this employee?";
export const DELETE_CLAIM_MESSAGE = "Are you sure want to delete this claim?";
export const DELETE_OP_NOTE_MESSAGE =
  "Are you sure want to delete this OP-Note?";
export const DELETE_OFFICE_MESSAGE = "Are you sure want to delete this office?";
export const DELETE_PATIENT_MESSAGE =
  "Claims related to this patient will be deleted too.";
export const DOCTOR_SWITCH =
  "Are you sure you want to switch doctor and office?";
export const DELETE_NOTICE_MESSAGE = "Are you sure want to delete this notice?";
export const DELETE_IMAGE_MESSAGE = "Are you sure want to delete this image?";
export const DOCTOR_SWITCH_SUCCESSFULLY = "Switched successfully";
export const OFFICE_ALREADY_EXISTS = "Office already exists";
export const NOTE_SENT_CLAIM_MESSAGE = "Are you sure to check?";
export const NOTE_NOT_SENT_CLAIM_MESSAGE = "Are you sure to uncheck?";

// Payer
export const PAYER_NAME = "Please Enter Payer Name";
export const PROCEDURE_VALUE_LESS_THAN_2 = "Procedure value should be 1 or 2 ";
export const PROCEDURE_VALUE_MORE_THAN_0 = "Please Enter Value greater than 0";

// ELIGIBILITY

export const GOOD_TO_GO = "GOOD TO GO";
export const SCAN_PPO_INSURANCE_CARD =
  "SCAN PPO INSURANCE CARD BOTH FRONT AND BACK";
export const CAN_NOT_BILLED = "CAN NOT BE BILLED UNLESS DOCTOR IS IN NETWORK";
export const NO_MEDICARE_PART_B =
  "Medicare Part B Inactive; Part B must be activated.";
export const NO_MEDICARE_PART_A =
  "Medicare Part A Inactive; Part A must be activated.";
export const SCAN_PPO_INSURANCE_CARD_20 =
  "SCAN PPO INSURANCE CARD BOTH FRONT AND BACK ,20% IS MEDICAID(CAN NOT COLLECT UNLESS DOCTOR IS IN NETWORK)";
export const GOOD_TO_GO_20 =
  "GOOD TO GO ,20% IS MEDICAID(CAN NOT COLLECT UNLESS DOCTOR IS IN NETWORK)";
export const MWB_INS_MSG = "COLLECT WORK INSURANCE CARD FROM PATIENT OR SPOUSE";

// NOTICE
export const ENTER_TITLE = "Please Enter Title";
export const SELECT_POPUP = "Please Select PopUp";
export const SELECT_POSTING_FROM_PERIOD_DATE =
  "Please Select Posting Period From Date";
export const SELECT_POSTING_TO_PERIOD_DATE =
  "Please Select Posting Period To Date";
export const ENTER_CONTENTS = "Please Enter Contents";
export const SELECT_NOTICE = "Please select notice that you want to delete";

//OP-NOTE
export const ENTER_SIGNATURE = "Please Enter signature";
export const FILL_MENDATORY_FIELDS = "Please fill all the mendatory fields";

//PATIENT-CHART
export const ENTER_TAB_NAME = "Please enter tab name";
export const ENTER_GROUP_NAME = "Please enter group name";
export const ENTER_TAB_PRIORITY = "Please enter tab priority";
export const ENTER_QUESTION_NAME = "Please enter question";

export const MAX_PRIORITY = 9999;
export const MIN_PRIORITY = -1;
export const ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY = `Please enter priority less than ${MAX_PRIORITY}`;
export const ARE_YOU_SURE_YOU_WANT_YO_UPLOAD_THIS_FILE =
  "Are you sure, you want to upload this file";

//ROLE
export const ENTER_ROLE = "Please Enter Role";
export const SELECT_PAGES_MESSAGE = "Please Select Pages";

//COMPREHENSIVE
export const SELECT_PATIENT_MESSAGE = "Please select patient";
export const SELECT_RENDERINGPROVIDER = "Please select rendering provider";
export const ENTER_SERVICE_DATE = "Please enter service date";
export const DELETE_COMPREHENSIVE_NOTE_MESSAGE =
  "Are you sure want to delete this comprehensive note?";
export const ENTER_DESCRIPTION = "Please enter description";

//DOCUMENT
export const DELETE_DOCUMENT_MESSAGE =
  "Are you sure want to delete this Document?";

// export const DELETE_DOCUMENT_DOCUMENT_MESSAGE = "Are you sure want to delete this document?";
export const VERIFY_DOCUMENT_MESSAGE =
  "Are you sure want to verify this document?";
export const SELECT_DOCUMENT_TYPE_MESSAGE = "Please select document type";

//PRE_AUTHORIZATION

export const DELETE_PRE_AUTHORIZATION_NOTE_MESSAGE =
  "Are you sure want to delete this pre-authorization note?";

export const ENTER_RECEIPT_NO = "Please enter Receipt #";
export const ENTER_RECEIPT_DATE = "Please enter Receipt Date";
export const ENTER_RECEIPT_AMOUNT = "Please enter BWS FEE";
