import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseURL } from "../../Config";
import { DOCTOR_PAGES, PATIENT_DOCUMENT_TYPE } from "../../Helper/Constants";
import axios from "axios";
import eligibilityInfo from "../../data/eligibilityInfo";

import {
  FIRST_NAME,
  GENDER,
  LAST_NAME,
  MALE,
  FEMALE,
  ADD_ELIGIBLE_PATIENT,
  PATIENT_INFO,
  BIRTH_DATE,
  ADDRESS,
  DATE_OF_SERVICE,
  CHECK,
  HOME,
  DASH,
  RELATIONSHIP_SHIP_OPTIONS,
  ELIGIBILITY_DETAILS,
  NAME,
  POLICY,
  ELIGIBILITY,
  INSURANCE_COVERAGE_SUMMARY,
  EFFECTIVE_DATE,
  CO_INSURANCE,
  DEDUCTIBLE,
  SELECT_PAYER,
  SELECT_RELATIONSHIP,
  DEDUCTIBLE_REMAINING,
  CO_ORDINATION_OF_BENEFITS,
  PLAN_NUMBER,
  PLAN_SPONSER,
  PLAN_NETWORK_ID,
  PRIMARY_PAYER,
  POLICY_NUMBER,
  PHONE_NO,
  WEBSITE,
  HMO,
  PPO,
  MWB,
  SAVE_PATIENT,
  ELIGIBILITY_CHECK,
  WHAT_TO_DO_NEXT,
  INSURANCE_FRONT,
  NO,
  FILE_NAME,
  THUMBNAIL,
  ACTION,
  INSURANCE_BACK,
  XRAY,
  NOTE,
  ID,
  FINISH,
  PATIENT_DOCUMENTS,
  CREATED_DATE,
} from "../../Helper/Constants";
import {
  CHECK_ELIGIBLITY,
  DELETE_PATIENT_DOCUMENT,
  ELIGIBILITY_HISTORY_CHECK_API,
  PATIENT_DOCUMENT_LIST,
  PATIENT_EDIT_WITHOUT_ELIGIBILITY,
  PATIENT_PROFILE,
  PAYER_LIST_API,
} from "../../Helper/ApiPath";
import { NavLink, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateAPIFormate, dateFormateMonthDateYear } from "../../Helper/Helper";
import NoDataFound from "../CommonComponent/NoDataFound";
import _ from "lodash";
import {
  CAN_NOT_BILLED,
  DELETE_IMAGE_MESSAGE,
  ENTER_DOB,
  ENTER_DOS,
  ENTER_FNAME,
  ENTER_LNAME,
  ENTER_POLICY_NUMBER,
  GOOD_TO_GO,
  GOOD_TO_GO_20,
  NO_MEDICARE_PART_B,
  SCAN_PPO_INSURANCE_CARD,
  SCAN_PPO_INSURANCE_CARD_20,
  MWB_INS_MSG,
  PREAUTHORIZATION_REQUIRED,
  TRADITIONAL_20,
  TRADITIONAL,
} from "../../Helper/Messages";
import Select from "react-select";
import Eligibility_sample from "../Forms/Eligibility_sample";
import Cookies from "universal-cookie";
import Zoom from "react-medium-image-zoom";
import Swal from "sweetalert2";
import UploadImage from "../Forms/Patients/UploadImage";
import WebcamUpload from "../Forms/Patients/WebcamUpload";

function EligibiltyCheck() {
  let doctorToken = JSON.parse(localStorage.getItem("DoctorToken"));
  const logToken = JSON.parse(localStorage.getItem("logToken")).token;
  let doctorPracticeId = localStorage.getItem("doctorPracticeId");
  let patientId = localStorage.getItem("patientId");
  let payerName = localStorage.getItem("payerName");
  let payerValue = localStorage.getItem("payerValue");

  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ins_addr_1, setins_addr_1] = useState("");
  const [ins_city, setins_city] = useState("");
  const [ins_state, setins_state] = useState("");
  const [ins_zip, setins_zip] = useState("");
  const [ins_dob, setins_dob] = useState("");
  const [ins_name_f, setins_name_f] = useState("");
  const [ins_name_l, setins_name_l] = useState("");
  const [ins_number, setins_number] = useState("");
  const [ins_sex, setins_sex] = useState("");
  const [deductibleAmount, setdeductibleAmount] = useState("");
  const [deductibleRemaining, setdeductibleRemaining] = useState("");
  const [coinsurancePercent, setcoinsurancePercent] = useState("");
  const [eligibility_date, seteligibility_date] = useState("");
  const [eligibility, setEligibility] = useState({});
  const [plan_begin_date, setplan_begin_date] = useState("");
  const [group_name, setgroup_name] = useState("");
  var [deductiblesList, setdeductiblesList] = useState([]);
  const [Hmo, setHmo] = useState({});
  const [Ppo, setPpo] = useState({});
  const [Mwb, setMwb] = useState({});
  const cookies = new Cookies();
  let isTrial = cookies.get("isTrial");
  if (isTrial) {
    isTrial = cookies.get("isTrial").isTrial;
  }
  let pageAccess = cookies.get("doctorPageAccess")
    ? cookies.get("doctorPageAccess").length
      ? cookies.get("doctorPageAccess")
      : [14, 15, 16, 17, 18, 19, 20]
    : "";

  const [patient, setPatient] = useState({
    Procedure_Code: "",
    firstName: "",
    lastName: "",
    prov_npi: "1588288591",
    Policy_No: "",
    dob: "",
  });

  const [insuranceF, setInsuranceF] = useState([]);
  const [insuranceB, setInsuranceB] = useState([]);
  const [imageX, setImageX] = useState([]);
  const [imageN, setImageN] = useState([]);
  const [imageId, setImageId] = useState([]);
  const [popUpTitle, setPopUpTitle] = useState("");
  const [imageType, setImageType] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [show, setShow] = useState(false);
  const [webcame, setWebcame] = useState(false);
  const [edit, setEdit] = useState(false);
  let [image, setImage] = useState({ preview: "", data: "" });
  const [showImageSection, setShowImageSection] = useState(false);

  const isKaiser = (name = "") => {
    return name.toUpperCase()?.trim() === "KAISER PERMANENTE INSURANCE COMPANY";
  };

  const handleImageChange = (e) => {
    const data = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
      name: e.target.files[0].name,
    };
    const fileExtension = data.data.name.split(".").at(-1).toLowerCase();
    let allowedFileTypes =
      imageType == PATIENT_DOCUMENT_TYPE.NOTE ||
      imageType == PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT ||
      imageType == PATIENT_DOCUMENT_TYPE.INSURANCE_BACK ||
      imageType == PATIENT_DOCUMENT_TYPE.X_RAY ||
      imageType == PATIENT_DOCUMENT_TYPE.ID
        ? ["pdf", "jpg", "png", "gif", "jpeg"]
        : ["jpg", "png", "gif", "jpeg"];

    if (!allowedFileTypes.includes(fileExtension)) {
      e.target.value = null;
      alert(
        `File does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
    } else {
      let element = document.getElementById("button");
      element.classList.remove("d-none");
      setImage(data);
    }
  };
  const fetchThumbnailData = async (type) => {
    axios
      .post(`${baseURL}/${PATIENT_DOCUMENT_LIST}`, {
        headers: { authorization: doctorToken },
        type: type,
        patientId: patientId,
      })
      .then((res) => {
        if (type == PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT) {
          setInsuranceF(res.data.data);
        }
        if (type == PATIENT_DOCUMENT_TYPE.INSURANCE_BACK) {
          setInsuranceB(res.data.data);
        }
        if (type == PATIENT_DOCUMENT_TYPE.X_RAY) {
          setImageX(res.data.data);
        }
        if (type == PATIENT_DOCUMENT_TYPE.NOTE) {
          setImageN(res.data.data);
        }
        if (type == PATIENT_DOCUMENT_TYPE.ID) {
          setImageId(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleEdit = (title, id, type) => {
    setShow(true);
    setEdit(true);
    setPopUpTitle(title);
    setDocumentId(id);
    setImageType(type);
  };

  const handleClick = (title, type) => {
    setShow(true);
    setPopUpTitle(title);
    setImageType(type);
  };

  const handleClose = () => {
    setShow(false);
    setEdit(false);
  };
  const handleWebcameClick = (title, type) => {
    setWebcame(true);
    setPopUpTitle(title);
    setImageType(type);
  };

  const handleWebcameClose = () => {
    setWebcame(false);
  };

  const handleDelete = (id, type, patientId) => {
    Swal.fire({
      title: DELETE_IMAGE_MESSAGE,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          await axios
            .delete(`${baseURL}/${DELETE_PATIENT_DOCUMENT}/${id}`, {
              headers: { authorization: doctorToken },
            })
            .then((res) => {
              Swal.fire(res.data.message, " ", "success");
              fetchThumbnailData(type, patientId);
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status == 500) {
                  localStorage.clear();
                  navigate("/login");
                } else {
                  toast.error(err.response.data.message);
                }
              }
            });
        }
      }
    });
  };

  const btnFinish = () => {
    navigate("/managePatient");
  };

  const patientDetail = async () => {
    await axios
      .get(`${baseURL}/${PATIENT_PROFILE}/${patientId}`, {
        headers: { authorization: logToken },
      })
      .then(async (res) => {
        setPatient({
          firstName: res.data.data.firstName,
          lastName: res.data.data.lastName,
          dob: dateFormateMonthDateYear(res.data.data.dob),
          prov_npi: "1588288591",
          Policy_No: res.data.data.MedicarePatientId,
          Procedure_Code: "",
          created_at: "",
          created_by: "",
        });
      })

      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPatient({ ...patient, [name]: value });
  };

  const [payerDetail, setPayerDetail] = useState({
    Payer: payerValue == "null" ? "01192" : payerValue,
  });

  const handlePayerChange = (e) => {
    setPayerDetail({ Payer: e.value });
  };

  const [benefitType, setBenefitTypeDetail] = useState({
    Benefit_Type: "30",
  });

  const handleBenefitTypeChange = (e) => {
    setBenefitTypeDetail({ Benefit_Type: e.value });
  };

  const [relationShip, setRelationShipDetail] = useState({
    RelationShip: "18",
  });

  const handleRelationShipChange = (e) => {
    setRelationShipDetail({ RelationShip: e.value });
  };
  const handleCheck = async (e) => {
    e.preventDefault();
    setHmo("");
    setPpo("");
    setMwb("");
    setgroup_name("");
    if (!payerDetail.Payer) {
      toast.error("Please Select Payer..");
    } else if (!benefitType.Benefit_Type) {
      toast.error("Please Select Benefit Type..");
    } else if (!patient.firstName) {
      toast.error(ENTER_FNAME);
    } else if (!patient.lastName) {
      toast.error(ENTER_LNAME);
    } else if (!relationShip.RelationShip) {
      toast.error(SELECT_RELATIONSHIP);
    } else if (!patient.Policy_No) {
      toast.error(ENTER_POLICY_NUMBER);
    } else if (!patient.dob) {
      toast.error(ENTER_DOB);
    } else if (!patient.dos) {
      toast.error(ENTER_DOS);
    } else {
      setIsLoading(true);
      // const axiosConfig = {
      //     headers: {
      //         "Content-Type": "multipart/form-data",
      //         Accept: "application/json",
      //         "Access-Control-Allow-Origin": "*",
      //     },
      // };
      const postData = {};
      postData.ins_name_l = patient.lastName; // "CHASE";
      postData.ins_name_f = patient.firstName; //"BARRY";
      postData.ins_name_m = "";
      postData.payerid = payerDetail.Payer;
      postData.pat_rel = relationShip.RelationShip;
      postData.fdos = dateAPIFormate(patient.dos); //"2022-04-24";
      postData.ins_number = patient.Policy_No; //"7JF5M18RF87";
      postData.ins_dob = dateAPIFormate(patient.dob); //"1955-08-17";
      postData.service_code = benefitType.Benefit_Type;
      postData.proc_code = patient.Procedure_Code;
      postData.prov_npi = patient.prov_npi;
      // postData.AccountKey = "11777vRfaHlBjmaTxUHjbvzMKFrku";
      axios
        .post(`${baseURL}/${CHECK_ELIGIBLITY}`, postData)
        .then(async (response) => {
          // console.log("elig", response.data.data);
          if (response.data.data.error) {
            toast.error(response.data.data.error.error_mesg);
          } else if (response.data.data.elig.error) {
            toast.error(response.data.data.elig.error[0].error_mesg);
          } else {
            setStatus(true);
            var elig = response.data.data.elig;
            setEligibility(response.data.elig);

            setins_addr_1(elig.ins_addr_1);
            setins_city(elig.ins_city);
            setins_state(elig.ins_state);
            setins_zip(elig.ins_zip);
            setins_dob(elig.ins_dob);
            setins_name_f(elig.ins_name_f);
            setins_name_l(elig.ins_name_l);
            setins_number(elig.ins_number);
            setins_sex(elig.ins_sex);
            seteligibility_date(elig.eligibility_date);
            // var allBenefits = elig.benefit;
            var allBenefits = elig.benefit?.filter((benefit) => {
              return (
                benefit.benefit_description ===
                  "Health Benefit Plan Coverage" ||
                benefit.benefit_code === "30"
              );
            });
            var groupobj = _.find(allBenefits, {
              insurance_type_description: "Qualified Medicare Beneficiary",
            });
            var railroad = _.find(allBenefits, {
              benefit_notes:
                "0-Beneficiary insured due to age OASI Railroad Retirement Medicare Beneficiary.",
            });
            // var noMedicare = allBenefits.find(function (result) {
            //     return (
            //         result.insurance_type_description === "Medicare Part B" &&
            //         result.benefit_description === "Health Benefit Plan Coverage" &&
            //         result.benefit_coverage_description === "Inactive"
            //     );
            // });
            var noMedicare = allBenefits.find(function (result) {
              return (
                result.insurance_type_code === "MB" &&
                result.benefit_coverage_description === "Inactive"
              );
            });
            var medicareBActive = allBenefits.find(function (result) {
              return (
                result.insurance_type_code === "MB" &&
                result.benefit_coverage_description === "Active Coverage"
              );
            });
            // if (groupobj) {
            //     setgroup_name("Qualified Medicare Beneficiary");
            // } else if (railroad) {
            //     setgroup_name("Medicare Railroad");
            // } else if (noMedicare) {
            //     setgroup_name("No Medicare Part B");
            // } else {
            //     setgroup_name("Medicare Part B");
            // }
            var groupName = "";
            if (noMedicare) {
              groupName = "No Medicare Part B";
              setgroup_name("No Medicare Part B");
            } else if (groupobj) {
              groupName = "Qualified Medicare Beneficiary";
              setgroup_name("Qualified Medicare Beneficiary");
            } else if (railroad) {
              groupName = "Medicare Railroad";
              setgroup_name("Medicare Railroad");
            } else {
              groupName = "Medicare Part B";
              setgroup_name("Medicare Part B");
            }

            var objHMO = _.find(allBenefits, {
              insurance_type_description:
                "Health Maintenance Organization (HMO)",
            });

            if (objHMO) {
              setHmo(objHMO);
            } else {
              objHMO = _.find(allBenefits, {
                insurance_type_description:
                  "Health Maintenance Organization (HMO) - Medicare Risk",
              });
              if (objHMO) {
                setHmo(objHMO);
              }
            }
            var objPPO = _.find(allBenefits, {
              insurance_type_description:
                "Preferred Provider Organization (PPO)",
            });

            if (objPPO) {
              setPpo(objPPO);
            }

            var objMWB = _.find(allBenefits, {
              insurance_type_description:
                "Medicare Secondary Working Aged Beneficiary or Spouse with Employer Group Health Plan",
            });

            if (objMWB) {
              setMwb(objMWB);
            }

            var objdeductAmt = allBenefits.filter(function (result) {
              return (
                (result.insurance_type_description === "Medicare Part B" ||
                  result.benefit_notes ==
                    "0-Beneficiary insured due to age OASI Railroad Retirement Medicare Beneficiary.") &&
                result.benefit_description === "Health Benefit Plan Coverage" &&
                result.benefit_coverage_description === "Deductible" &&
                result.benefit_period_description === "Calendar Year"
              );
            });
            if (objdeductAmt.length > 0) {
              setdeductibleAmount(objdeductAmt[0].benefit_amount);
            }

            var objdeductAmt1 = await allBenefits.filter(function (result) {
              return (
                result.insurance_type_description ===
                  "Qualified Medicare Beneficiary" &&
                result.benefit_description === "Health Benefit Plan Coverage" &&
                result.benefit_coverage_description === "Deductible" &&
                result.benefit_period_description === "Calendar Year"
              );
            });
            if (objdeductAmt1.length > 0) {
              setdeductibleAmount(objdeductAmt1[0].benefit_amount);
            }

            setdeductibleRemaining(0);
            var objdeductibleRemaining = await allBenefits.filter(function (
              result
            ) {
              return (
                (result.insurance_type_description === "Medicare Part B" ||
                  result.benefit_notes ==
                    "0-Beneficiary insured due to age OASI Railroad Retirement Medicare Beneficiary.") &&
                result.benefit_description === "Health Benefit Plan Coverage" &&
                result.benefit_coverage_description === "Deductible" &&
                result.benefit_period_description === "Remaining"
              );
            });

            if (objdeductibleRemaining.length > 0) {
              setdeductibleRemaining(objdeductibleRemaining[0].benefit_amount);
            }

            var objdeductibleRemainingQMO = await allBenefits.filter(function (
              result
            ) {
              return (
                result.insurance_type_description === "Medicare Part B" &&
                  result.benefit_description === "Health Benefit Plan Coverage",
                result.benefit_coverage_description === "Deductible" &&
                  result.benefit_period_description === "RemainingQMO Case"
              );
            });

            if (objdeductibleRemainingQMO.length > 0) {
              setdeductibleRemaining(
                objdeductibleRemainingQMO[0].benefit_amount
              );
            }

            var objcoinsurance = await allBenefits.filter(function (result) {
              return (
                (result.insurance_type_description === "Medicare Part B" ||
                  result.benefit_notes ==
                    "0-Beneficiary insured due to age OASI Railroad Retirement Medicare Beneficiary.") &&
                result.benefit_description === "Health Benefit Plan Coverage" &&
                result.benefit_coverage_description === "Co-Insurance"
              );
            });
            if (objcoinsurance.length > 0) {
              setcoinsurancePercent(objcoinsurance[0].benefit_percent);
            }

            var objcoinsurance1 = await allBenefits.filter(function (result) {
              return (
                result.insurance_type_description ===
                  "Qualified Medicare Beneficiary" &&
                result.benefit_description === "Health Benefit Plan Coverage" &&
                result.benefit_coverage_description === "Co-Insurance"
              );
            });

            if (objcoinsurance1.length > 0) {
              setcoinsurancePercent(objcoinsurance1[0].benefit_percent);
            }

            var objplan_begin_date = await allBenefits.filter(function (
              result
            ) {
              return (
                (result.insurance_type_description === "Medicare Part B" ||
                  result.benefit_notes ==
                    "0-Beneficiary insured due to age OASI Railroad Retirement Medicare Beneficiary.") &&
                result.benefit_description === "Health Benefit Plan Coverage" &&
                result.benefit_coverage_description === "Active Coverage"
              );
            });

            if (objplan_begin_date.length > 0) {
              setplan_begin_date(objplan_begin_date[0]?.plan_date);
            }

            var objplan_begin_date1 = await allBenefits.filter(function (
              result
            ) {
              return (
                result.insurance_type_description === "Medicare Part B" &&
                result.benefit_description === "Health Benefit Plan Coverage" &&
                result.benefit_coverage_description === "Active Coverage"
              );
            });

            if (objplan_begin_date1.length > 0) {
              setplan_begin_date(objplan_begin_date1[0]?.plan_date);
            }
          }
          console.log(objplan_begin_date, " dakshdkashdkahs");
          await axios
            .post(`${baseURL}/${ELIGIBILITY_HISTORY_CHECK_API}`, {
              headers: { authorization: doctorToken },
              patientId,
              dateOfService: dateAPIFormate(patient.dos),
              result:
                groupName == "Medicare Part B" && !objHMO && !objPPO && !objMWB
                  ? TRADITIONAL
                  : groupName == "Medicare Part B" &&
                    !objHMO &&
                    objPPO &&
                    isKaiser(objPPO?.entity_name?.[0])
                  ? "CAN NOT BE BILLED"
                  : groupName == "Medicare Railroad" &&
                    !objHMO &&
                    objPPO &&
                    isKaiser(objPPO?.entity_name?.[0])
                  ? "CAN NOT BE BILLED"
                  : groupName == "Medicare Part B" && !objHMO && objPPO
                  ? SCAN_PPO_INSURANCE_CARD
                  : groupName == "Medicare Part B" && objHMO && !objPPO
                  ? CAN_NOT_BILLED
                  : groupName == "Medicare Part B" &&
                    !objHMO &&
                    !objPPO &&
                    objMWB
                  ? MWB_INS_MSG
                  : groupName == "Qualified Medicare Beneficiary" &&
                    !objHMO &&
                    !objPPO &&
                    !objMWB
                  ? TRADITIONAL_20
                  : groupName == "Qualified Medicare Beneficiary" &&
                    !objHMO &&
                    objPPO
                  ? SCAN_PPO_INSURANCE_CARD_20
                  : groupName == "Qualified Medicare Beneficiary" &&
                    objHMO &&
                    !objPPO
                  ? PREAUTHORIZATION_REQUIRED
                  : groupName == "Qualified Medicare Beneficiary" &&
                    !objHMO &&
                    !objPPO &&
                    objMWB
                  ? MWB_INS_MSG
                  : groupName == "Medicare Railroad" &&
                    !objHMO &&
                    !objPPO &&
                    !objMWB
                  ? GOOD_TO_GO
                  : groupName == "Medicare Railroad" && !objHMO && objPPO
                  ? SCAN_PPO_INSURANCE_CARD
                  : groupName == "Medicare Railroad" && objHMO && !objPPO
                  ? PREAUTHORIZATION_REQUIRED
                  : groupName == "Medicare Railroad" &&
                    !objHMO &&
                    !objPPO &&
                    objMWB
                  ? MWB_INS_MSG
                  : groupName == "No Medicare Part B"
                  ? NO_MEDICARE_PART_B
                  : "",
              // name: group_name,
              name: medicareBActive
                ? "Medicare Part B"
                : noMedicare
                ? "No Medicare Part B"
                : groupobj
                ? "Qualified Medicare Beneficiary"
                : railroad
                ? "Medicare Railroad"
                : "",
              effectiveDate: dateAPIFormate(objplan_begin_date[0]?.plan_date),
              coInsurance: noMedicare
                ? ""
                : objcoinsurance.length > 0
                ? objcoinsurance[0].benefit_percent
                : objcoinsurance1.length > 0
                ? objcoinsurance1[0].benefit_percent
                : "",
              deductible:
                objdeductAmt.length > 0
                  ? objdeductAmt[0].benefit_amount
                  : objdeductAmt1.length > 0
                  ? objdeductAmt1[0].benefit_amount
                  : "",
              deductibleRemaining:
                objdeductibleRemaining.length > 0
                  ? objdeductibleRemaining[0].benefit_amount
                  : objdeductibleRemainingQMO.length > 0
                  ? objdeductibleRemainingQMO[0].benefit_amount
                  : "",
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status == 500) {
                  localStorage.clear();
                  navigate("/login");
                } else {
                  toast.error(err.response.data.message);
                }
              }
            });
        })
        .catch((error) => {
          console.log("err", error);

          setStatus(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleApply = async (e) => {
    e.preventDefault();

    if (status == 1) {
      await axios
        .post(`${baseURL}/${PATIENT_EDIT_WITHOUT_ELIGIBILITY}`, {
          headers: { authorization: doctorToken },
          benefits: JSON.stringify(eligibility),
          deductibles: JSON.stringify(deductiblesList),
          practiceId: doctorPracticeId,
          patientId: patientId,
          firstName: ins_name_f,
          lastName: ins_name_l,
          gender: ins_sex == "M" ? "male" : "female",
          dob: dateAPIFormate(ins_dob),
          address1: ins_addr_1,
          state: ins_state,
          city: ins_city,
          zip: ins_zip,
          madicareEligibilityStartAt: dateAPIFormate(plan_begin_date),
          MedicarePatientId: ins_number,

          PPOInsuranceName: Ppo ? Ppo.mco_name : "",
          PPOInsuranceNumber: Ppo ? Ppo.plan_number : "",
          PPOPayerId: Ppo ? Ppo.entity_name[0] : "",
          PPOPayerAddress: Ppo ? Ppo.entity_addr_1[0] : "",
          PPOPhone: Ppo ? Ppo.entity_phone[0] : "",

          HMOInsuranceName: Hmo ? Hmo.mco_name : "",
          HMOInsuranceNumber: Hmo ? Hmo.plan_number : "",
          HMOPayerId: Hmo ? Hmo.entity_name : "",
          HMOPayerAddress: Hmo ? Hmo.entity_addr_1 : "",
          HMOPhone: Hmo ? Hmo.entity_phone : "",

          isMedicareEligible: 1,
          isEligibleUpdate: 1,
        })
        .then((res) => {
          toast.success(res.data.message);
          document.getElementById("applyRow").style.display = "none";
          setPatient("");
          setShowImageSection(true);
          fetchThumbnailData(PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT, patientId);
          fetchThumbnailData(PATIENT_DOCUMENT_TYPE.INSURANCE_BACK, patientId);
          fetchThumbnailData(PATIENT_DOCUMENT_TYPE.X_RAY, patientId);
          fetchThumbnailData(PATIENT_DOCUMENT_TYPE.NOTE, patientId);
          fetchThumbnailData(PATIENT_DOCUMENT_TYPE.ID, patientId);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 500) {
              localStorage.clear();
              navigate("/login");
            } else {
              toast.error(err.response.data.message);
            }
          }
        });
    }
  };
  const [payer, setPayer] = useState([]);
  const payerList = () => {
    axios
      .get(`${baseURL}/${PAYER_LIST_API}`, {
        headers: { authorization: logToken },
      })
      .then((res) => {
        setPayer(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const payerOptions =
    payer &&
    payer.map((item, i) => {
      return {
        value: item.value,
        label: item.name,
      };
    });

  const benefit_TypeOptions =
    eligibilityInfo &&
    eligibilityInfo.map((item, i) => {
      return {
        value: item.value,
        label: item.label,
      };
    });

  const relationShipOptions =
    RELATIONSHIP_SHIP_OPTIONS &&
    RELATIONSHIP_SHIP_OPTIONS.map((item, i) => {
      return {
        value: item.value,
        label: item.label,
      };
    });

  useEffect(() => {
    payerList();
    patientDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="page-bar">
            <div className="page-title-breadcrumb">
              <div className=" pull-left">
                <div className="page-title">{ELIGIBILITY_CHECK}</div>
              </div>
              {isTrial == 0 || pageAccess.includes(DOCTOR_PAGES.Patient) ? (
                <ol className="breadcrumb page-breadcrumb pull-right">
                  <li>
                    <i className="fa fa-home"></i>&nbsp;
                    <NavLink className="parent-item" to="/dashboard">
                      {HOME}
                    </NavLink>
                    &nbsp;<i className="fa fa-angle-right"></i>
                  </li>
                  <li>
                    <i className="fa fa-user"></i>&nbsp;
                    <NavLink className="parent-item" to="/patientProfile">
                      Patient Detail
                    </NavLink>
                    &nbsp;<i className="fa fa-angle-right"></i>
                  </li>
                  <li className="active">{ADD_ELIGIBLE_PATIENT}</li>
                </ol>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="card card-box">
                <div className="card-head">
                  <header>
                    {showImageSection ? PATIENT_DOCUMENTS : PATIENT_INFO}
                  </header>
                </div>
                <div className="card-body" id="bar-parent">
                  {showImageSection == false ? (
                    <form id="form_sample_1" className="form-horizontal">
                      <div className="form-body">
                        <div className="form-row row">
                          <div className="form-group row">
                            <label className="control-label col-md-2">
                              Payer
                              <span className="required"> * </span>
                            </label>
                            <div className="col-md-3">
                              <Select
                                name="Payer"
                                defaultValue={{
                                  label:
                                    payerName == "null"
                                      ? "CA Medicare South"
                                      : payerName,
                                }}
                                options={payerOptions}
                                onChange={handlePayerChange}
                                placeholder={SELECT_PAYER}
                              ></Select>
                            </div>

                            <label className="control-label col-md-2">
                              Benefit Type
                              <span className="required"> * </span>
                            </label>
                            <div className="col-md-3">
                              <Select
                                name="Benefit_Type"
                                defaultValue={{
                                  label: "Health Benefit Plan Coverage",
                                }}
                                options={benefit_TypeOptions}
                                onChange={handleBenefitTypeChange}
                                placeholder="Select Benefit Type"
                              ></Select>
                            </div>
                          </div>
                        </div>
                        <div className="form-row row">
                          <div className="form-group row">
                            <label className="control-label col-md-2">
                              Procedure Code
                            </label>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Procedure Code "
                                name="Procedure_Code"
                                value={patient && patient.Procedure_Code}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-row row">
                          <div className="card-head">
                            <header className="col-10">
                              {" "}
                              Subscriber information
                            </header>
                            <header className="col-2 d-inline-flex justify-content-end">
                              <Eligibility_sample />
                            </header>
                          </div>

                          <div className="form-row row">
                            <div className="form-group row  mt-5">
                              <label className="control-label col-md-2">
                                {FIRST_NAME}
                                <span className="required"> * </span>
                              </label>
                              <div className="col-md-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter First Name"
                                  name="firstName"
                                  value={patient && patient.firstName}
                                  readOnly
                                  // onChange={handleChange}
                                />
                              </div>

                              <label className="control-label col-md-2">
                                {LAST_NAME}
                                <span className="required"> * </span>
                              </label>
                              <div className="col-md-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Last Name"
                                  name="lastName"
                                  value={patient && patient.lastName}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-row row">
                            <div className="form-group row">
                              <label className="control-label col-md-2">
                                RelationShip
                                <span className="required"> * </span>
                              </label>
                              <div className="col-md-3">
                                <Select
                                  name="RelationShip"
                                  defaultValue={{ label: "Self" }}
                                  options={relationShipOptions}
                                  onChange={handleRelationShipChange}
                                  placeholder={SELECT_RELATIONSHIP}
                                ></Select>
                              </div>

                              <label className="control-label col-md-2">
                                Policy #<span className="required"> * </span>
                              </label>
                              <div className="col-md-3">
                                <input
                                  type="text"
                                  id="input2"
                                  className="form-control"
                                  name="Policy_No"
                                  placeholder="Enter Policy"
                                  value={
                                    patient.MedicarePatientId
                                      ? patient && patient.MedicarePatientId
                                      : patient && patient.Policy_No
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-row row">
                            <div className="form-group row">
                              <label className="control-label col-md-2">
                                {BIRTH_DATE}
                                <span className="required"> * </span>
                              </label>
                              <div className="col-md-3">
                                <DatePicker
                                  className="form-control input-height mr-sm-2"
                                  value={dateFormateMonthDateYear(
                                    patient && patient.dob
                                  )}
                                  placeholderText={"Please Select Date"}
                                  maxDate={new Date()}
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown={true}
                                  showYearDropdown
                                  showMonthDropdown
                                  onChange={(date) =>
                                    setPatient({ ...patient, dob: date })
                                  }
                                />
                              </div>

                              <label className="control-label col-md-2">
                                {DATE_OF_SERVICE}
                                <span className="required"> * </span>
                              </label>
                              <div className="col-md-2">
                                <DatePicker
                                  className="form-control input-height mr-sm-2"
                                  value={dateFormateMonthDateYear(patient.dos)}
                                  placeholderText={"Please Select Date"}
                                  maxDate={new Date()}
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown={true}
                                  scrollableMonthDropdown={true}
                                  showYearDropdown
                                  showMonthDropdown
                                  onChange={(date) =>
                                    setPatient({ ...patient, dos: date })
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-actions">
                            <div className="center">
                              <button
                                type="submit"
                                className="btn btn-info m-r-20"
                                onClick={handleCheck}
                              >
                                {CHECK}
                              </button>
                              {isLoading ? (
                                <i className="fa fa-spinner fa-spin fa-2x checkSpinner"></i>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        {status == true ? (
                          <>
                            <div id="applyRow">
                              <div className="form-group row">
                                <div className="col-2">
                                  <label className="control-label">
                                    {WHAT_TO_DO_NEXT}
                                  </label>
                                </div>
                                <div className="col-10">
                                  <input
                                    type="text"
                                    data-required="1"
                                    className={`form-control input-height ${
                                      group_name == "Medicare Part B" &&
                                      !Hmo &&
                                      !Ppo &&
                                      !Mwb
                                        ? // "text-success"
                                          ""
                                        : group_name == "Medicare Part B" &&
                                          !Hmo &&
                                          Ppo
                                        ? // "text-danger"
                                          ""
                                        : group_name == "Medicare Part B" &&
                                          Hmo &&
                                          !Ppo
                                        ? // "text-danger"
                                          ""
                                        : group_name == "Medicare Part B" &&
                                          !Hmo &&
                                          !Ppo &&
                                          Mwb
                                        ? "text-danger"
                                        : group_name ==
                                            "Qualified Medicare Beneficiary" &&
                                          !Hmo &&
                                          !Ppo &&
                                          !Mwb
                                        ? // "text-success"
                                          ""
                                        : group_name ==
                                            "Qualified Medicare Beneficiary" &&
                                          !Hmo &&
                                          Ppo
                                        ? // "text-danger"
                                          ""
                                        : group_name ==
                                            "Qualified Medicare Beneficiary" &&
                                          Hmo &&
                                          !Ppo
                                        ? // "text-danger"
                                          ""
                                        : group_name ==
                                            "Qualified Medicare Beneficiary" &&
                                          !Hmo &&
                                          !Ppo &&
                                          Mwb
                                        ? "text-danger"
                                        : group_name == "Medicare Railroad" &&
                                          !Hmo &&
                                          !Ppo &&
                                          !Mwb
                                        ? "text-success"
                                        : group_name == "Medicare Railroad" &&
                                          !Hmo &&
                                          Ppo
                                        ? "text-danger"
                                        : group_name == "Medicare Railroad" &&
                                          Hmo &&
                                          !Ppo
                                        ? "text-danger"
                                        : group_name == "Medicare Railroad" &&
                                          !Hmo &&
                                          !Ppo &&
                                          Mwb
                                        ? "text-danger"
                                        : group_name == "No Medicare Part B"
                                        ? "text-danger"
                                        : ""
                                    }`}
                                    style={{
                                      background: "yellow",
                                      fontWeight: "bold",
                                      fontSize: "x-large",
                                    }}
                                    value={
                                      group_name == "Medicare Part B" &&
                                      !Hmo &&
                                      !Ppo &&
                                      !Mwb
                                        ? TRADITIONAL
                                        : group_name == "Medicare Part B" &&
                                          !Hmo &&
                                          Ppo &&
                                          isKaiser(Ppo?.entity_name?.[0])
                                        ? "CAN NOT BE BILLED"
                                        : group_name == "Medicare Railroad" &&
                                          !Hmo &&
                                          Ppo &&
                                          isKaiser(Ppo?.entity_name?.[0])
                                        ? "CAN NOT BE BILLED"
                                        : group_name == "Medicare Part B" &&
                                          !Hmo &&
                                          Ppo
                                        ? SCAN_PPO_INSURANCE_CARD
                                        : group_name == "Medicare Part B" &&
                                          Hmo &&
                                          !Ppo
                                        ? PREAUTHORIZATION_REQUIRED
                                        : group_name == "Medicare Part B" &&
                                          !Hmo &&
                                          !Ppo &&
                                          Mwb
                                        ? MWB_INS_MSG
                                        : group_name ==
                                            "Qualified Medicare Beneficiary" &&
                                          !Hmo &&
                                          !Ppo &&
                                          !Mwb
                                        ? TRADITIONAL_20
                                        : group_name ==
                                            "Qualified Medicare Beneficiary" &&
                                          !Hmo &&
                                          Ppo
                                        ? SCAN_PPO_INSURANCE_CARD_20
                                        : group_name ==
                                            "Qualified Medicare Beneficiary" &&
                                          Hmo &&
                                          !Ppo
                                        ? PREAUTHORIZATION_REQUIRED
                                        : group_name ==
                                            "Qualified Medicare Beneficiary" &&
                                          !Hmo &&
                                          !Ppo &&
                                          Mwb
                                        ? MWB_INS_MSG
                                        : group_name == "Medicare Railroad" &&
                                          !Hmo &&
                                          !Ppo &&
                                          !Mwb
                                        ? GOOD_TO_GO
                                        : group_name == "Medicare Railroad" &&
                                          !Hmo &&
                                          Ppo
                                        ? SCAN_PPO_INSURANCE_CARD
                                        : group_name == "Medicare Railroad" &&
                                          Hmo &&
                                          !Ppo
                                        ? PREAUTHORIZATION_REQUIRED
                                        : group_name == "Medicare Railroad" &&
                                          !Hmo &&
                                          !Ppo &&
                                          Mwb
                                        ? MWB_INS_MSG
                                        : group_name == "No Medicare Part B"
                                        ? NO_MEDICARE_PART_B
                                        : ""
                                    }
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <div className="card">
                                    <div className="card-body no-padding height-9">
                                      <div className="row"></div>
                                      <div className="profile-usertitle">
                                        <div className="profile-usertitle-name">
                                          {ELIGIBILITY_DETAILS}
                                        </div>
                                      </div>
                                      <ul className="list-group list-group-unbordered">
                                        <li className="list-group-item">
                                          <b>{NAME} : </b>{" "}
                                          <a className="pull-right">
                                            {ins_name_f} {ins_name_l}
                                          </a>
                                        </li>
                                        <li className="list-group-item">
                                          <b>{POLICY} : </b>{" "}
                                          <a className="pull-right">
                                            {ins_number}
                                          </a>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                          <b>{BIRTH_DATE} : </b>{" "}
                                          {dateFormateMonthDateYear(ins_dob)}
                                          <b>{GENDER} : </b>{" "}
                                          <a className="pull-right">
                                            {ins_sex == "M" ? MALE : FEMALE}
                                          </a>
                                        </li>

                                        <li className="list-group-item">
                                          <b>{ADDRESS} : </b>{" "}
                                          <a className="pull-right">
                                            {ins_addr_1}
                                          </a>
                                        </li>
                                        <li className="list-group-item">
                                          <b>{ELIGIBILITY} : </b>{" "}
                                          <a className="pull-right">
                                            {dateFormateMonthDateYear(
                                              eligibility_date
                                            )}
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="card">
                                    <div className="card-body no-padding height-9">
                                      <div className="row"></div>
                                      <div className="profile-usertitle">
                                        <div className="profile-usertitle-name">
                                          {INSURANCE_COVERAGE_SUMMARY}
                                        </div>
                                      </div>
                                      <ul className="list-group list-group-unbordered">
                                        <li className="list-group-item">
                                          <b>{NAME} : </b>{" "}
                                          <a className="pull-right">
                                            {group_name}
                                          </a>
                                        </li>
                                        <li className="list-group-item">
                                          <b>{EFFECTIVE_DATE} : </b>{" "}
                                          <a className="pull-right">
                                            {plan_begin_date
                                              ? dateFormateMonthDateYear(
                                                  plan_begin_date
                                                )
                                              : DASH}
                                          </a>
                                        </li>
                                        {group_name == "No Medicare Part B" ? (
                                          <li className="list-group-item">
                                            <b>{CO_INSURANCE}: </b>{" "}
                                            <a className="pull-right">{DASH}</a>
                                          </li>
                                        ) : (
                                          <li className="list-group-item">
                                            <b>{CO_INSURANCE} : </b>{" "}
                                            <a className="pull-right">
                                              {coinsurancePercent
                                                ? coinsurancePercent + "%"
                                                : "0%"}
                                            </a>
                                          </li>
                                        )}
                                        <li className="list-group-item">
                                          <b>{DEDUCTIBLE} : </b>{" "}
                                          <a className="pull-right">
                                            {deductibleAmount
                                              ? "$" + deductibleAmount
                                              : DASH}
                                          </a>
                                        </li>
                                        <li className="list-group-item">
                                          <b>{DEDUCTIBLE_REMAINING} : </b>{" "}
                                          <a className="pull-right">
                                            {deductibleRemaining
                                              ? "$" + deductibleRemaining
                                              : DASH}
                                          </a>
                                        </li>
                                        {Hmo.coordination_of_benefits ? (
                                          <>
                                            <h3 className="profile-usertitle-name text-center">
                                              <b>{HMO}</b>
                                            </h3>

                                            <li className="list-group-item">
                                              <b>
                                                {CO_ORDINATION_OF_BENEFITS} :{" "}
                                              </b>{" "}
                                              <a className="pull-right">
                                                {dateFormateMonthDateYear(
                                                  Hmo.coordination_of_benefits
                                                )}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{PLAN_NUMBER} : </b>{" "}
                                              <a className="pull-right">
                                                {Hmo.plan_number
                                                  ? Hmo.plan_number
                                                  : DASH}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{PLAN_SPONSER} : </b>{" "}
                                              <a className="pull-right">
                                                {Hmo.mco_name
                                                  ? Hmo.mco_name
                                                  : DASH}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{PLAN_NETWORK_ID} : </b>{" "}
                                              <a className="pull-right">
                                                {Hmo.mco_number
                                                  ? Hmo.mco_number
                                                  : DASH}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{PRIMARY_PAYER} : </b>{" "}
                                              <a className="pull-right">
                                                {Hmo.entity_name
                                                  ? Hmo.entity_name
                                                  : DASH}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{ADDRESS} : </b>{" "}
                                              <a className="pull-right">
                                                {Hmo.entity_addr_1
                                                  ? Hmo.entity_addr_1 +
                                                    ", " +
                                                    Hmo.entity_city +
                                                    ", " +
                                                    Hmo.entity_state +
                                                    " " +
                                                    Hmo.entity_zip
                                                  : ""}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{PHONE_NO} : </b>{" "}
                                              <a className="pull-right">
                                                {Hmo.entity_phone
                                                  ? Hmo.entity_phone
                                                  : DASH}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{WEBSITE} : </b>{" "}
                                              <a
                                                href={Hmo.entity_website}
                                                target="_blank"
                                                className="pull-right"
                                              >
                                                {Hmo.entity_website
                                                  ? Hmo.entity_website
                                                  : DASH}
                                              </a>
                                            </li>
                                          </>
                                        ) : Ppo.coordination_of_benefits ? (
                                          <>
                                            <h3 className="profile-usertitle-name text-center">
                                              <b>{PPO}</b>
                                            </h3>
                                            <li className="list-group-item">
                                              <b>
                                                {CO_ORDINATION_OF_BENEFITS} :{" "}
                                              </b>{" "}
                                              <a className="pull-right">
                                                {dateFormateMonthDateYear(
                                                  Ppo.coordination_of_benefits
                                                )}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{PLAN_NUMBER} : </b>{" "}
                                              <a className="pull-right">
                                                {Ppo.plan_number}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{PLAN_SPONSER} : </b>{" "}
                                              <a className="pull-right">
                                                {Ppo.mco_name}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{PLAN_NETWORK_ID} : </b>{" "}
                                              <a className="pull-right">
                                                {Ppo.mco_number}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{PRIMARY_PAYER} : </b>{" "}
                                              <a className="pull-right">
                                                {Ppo.entity_name[0]}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{ADDRESS} : </b>{" "}
                                              <a className="pull-right">
                                                {Ppo.entity_addr_1[0] +
                                                  ", " +
                                                  Ppo.entity_city[0] +
                                                  ", " +
                                                  Ppo.entity_state[0] +
                                                  " " +
                                                  Ppo.entity_zip[0]}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{PHONE_NO} : </b>{" "}
                                              <a className="pull-right">
                                                {Ppo.entity_phone[0]}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{WEBSITE} : </b>{" "}
                                              <a
                                                href={Ppo.entity_website[0]}
                                                target="_blank"
                                                className="pull-right"
                                              >
                                                {Ppo.entity_website[0]}
                                              </a>
                                            </li>
                                          </>
                                        ) : Mwb.coordination_of_benefits ? (
                                          <>
                                            <h3 className="profile-usertitle-name text-center">
                                              <b>{MWB}</b>
                                            </h3>
                                            <li className="list-group-item">
                                              <b>
                                                {CO_ORDINATION_OF_BENEFITS} :{" "}
                                              </b>{" "}
                                              <a className="pull-right">
                                                {dateFormateMonthDateYear(
                                                  Mwb.coordination_of_benefits
                                                )}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>
                                                {Mwb.insurance_type_description +
                                                  " " +
                                                  Mwb.benefit_coverage_description}
                                              </b>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{POLICY_NUMBER} : </b>{" "}
                                              <a className="pull-right">
                                                {Mwb.policy_number}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{PRIMARY_PAYER} : </b>{" "}
                                              <a className="pull-right">
                                                {Mwb.entity_name}
                                              </a>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{Mwb.entity_addr_1}</b>
                                            </li>
                                            <li className="list-group-item">
                                              <b>{ADDRESS} : </b>{" "}
                                              <a className="pull-right">
                                                {Mwb.entity_city[0] +
                                                  ", " +
                                                  Mwb.entity_state[0] +
                                                  " " +
                                                  Mwb.entity_zip[0]}
                                              </a>
                                            </li>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="center">
                                  <button
                                    type="submit"
                                    className="btn btn-info m-r-20"
                                    onClick={handleApply}
                                  >
                                    {SAVE_PATIENT}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </form>
                  ) : (
                    <div id="imageSection">
                      <UploadImage
                        popUpTitle={popUpTitle}
                        show={show}
                        handleClose={handleClose}
                        patientId={patientId}
                        imageType={imageType}
                        edit={edit}
                        documentId={documentId}
                        handleImageChange={handleImageChange}
                        image={image}
                        fetchThumbnailData={() =>
                          fetchThumbnailData(imageType, patientId)
                        }
                      />
                      <WebcamUpload
                        popUpTitle={popUpTitle}
                        webcame={webcame}
                        handleWebcameClose={handleWebcameClose}
                        patientId={patientId}
                        imageType={imageType}
                        fetchThumbnailData={() =>
                          fetchThumbnailData(imageType, patientId)
                        }
                      />
                      <div className="row mx-2">
                        <div className="col-6">
                          <div className="d-flex justify-content-between my-2 mx-2">
                            <div>
                              <p
                                style={{
                                  fontSize: "Larger",
                                  fontWeight: "600",
                                }}
                              >
                                {INSURANCE_FRONT}
                              </p>
                            </div>
                            <div className="d-flex justify-content-center">
                              <div>
                                <button
                                  className="btn btn-info mx-2"
                                  style={{
                                    borderRadius: "50%",
                                    width: "35px",
                                    height: "35px",
                                  }}
                                  onClick={() =>
                                    handleClick(
                                      INSURANCE_FRONT,
                                      PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT
                                    )
                                  }
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ marginLeft: "-1px" }}
                                  ></i>{" "}
                                </button>
                                {/* <UploadImage /> */}
                              </div>
                              <div style={{ marginTop: "10px" }}>
                                <button
                                  onClick={() =>
                                    handleWebcameClick(
                                      INSURANCE_FRONT,
                                      PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT
                                    )
                                  }
                                >
                                  {" "}
                                  <i
                                    className="fa fa-camera "
                                    style={{ fontSize: "30px" }}
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-scrollable">
                            <table
                              className="table table-hover table-checkable order-column full-width"
                              id="example4"
                            >
                              <thead>
                                <tr>
                                  <th>{NO}</th>
                                  <th>{FILE_NAME}</th>
                                  <th>{THUMBNAIL}</th>
                                  <th>{CREATED_DATE}</th>
                                  <th>{ACTION}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!insuranceF ? (
                                  <>{<NoDataFound />}</>
                                ) : (
                                  insuranceF &&
                                  insuranceF.map((item, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                        <td>
                                          <div
                                            data-toggle="tooltip"
                                            title={item.fileName}
                                          >
                                            {item.fileName
                                              ? item.fileName.length > 20
                                                ? item.fileName.slice(0, 20) +
                                                  "..."
                                                : item.fileName
                                              : INSURANCE_FRONT + ".png"}
                                          </div>
                                        </td>
                                        <td>
                                          {item.image
                                            .split(".")
                                            .at(-1)
                                            .toLowerCase() == "pdf" ? (
                                            <button
                                              type="button"
                                              className="btn btn-outline-danger"
                                              onClick={() =>
                                                window.open(
                                                  item.image,
                                                  "_blank"
                                                )
                                              }
                                            >
                                              <i className="fa  fa-file-pdf-o fa-2x"></i>
                                            </button>
                                          ) : (
                                            <Zoom>
                                              <img
                                                src={item.image}
                                                width="100"
                                                height="100"
                                                alt="Patient Document"
                                                style={{
                                                  height: "50px",
                                                  width: "50px",
                                                }}
                                              />
                                            </Zoom>
                                          )}{" "}
                                        </td>
                                        <td>
                                          {item.created_at
                                            ? dateFormateMonthDateYear(
                                                item.created_at
                                              )
                                            : "-"}
                                        </td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-xs"
                                            onClick={() =>
                                              handleEdit(
                                                INSURANCE_FRONT,
                                                item.id,
                                                item.type
                                              )
                                            }
                                          >
                                            <i className="fa  fa-pencil"></i>
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-danger btn-xs"
                                            onClick={() =>
                                              handleDelete(
                                                item.id,
                                                item.type,
                                                patientId
                                              )
                                            }
                                          >
                                            <i className="fa  fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="d-flex justify-content-between my-2 mx-2">
                            <div>
                              <p
                                style={{
                                  fontSize: "Larger",
                                  fontWeight: "600",
                                }}
                              >
                                {INSURANCE_BACK}
                              </p>
                            </div>
                            <div className="d-flex justify-content-center">
                              <div>
                                <button
                                  className="btn btn-info mx-2"
                                  style={{
                                    borderRadius: "50%",
                                    width: "35px",
                                    height: "35px",
                                  }}
                                  onClick={() =>
                                    handleClick(
                                      INSURANCE_BACK,
                                      PATIENT_DOCUMENT_TYPE.INSURANCE_BACK
                                    )
                                  }
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ marginLeft: "-1px" }}
                                  ></i>{" "}
                                </button>
                              </div>
                              <div style={{ marginTop: "10px" }}>
                                <button
                                  onClick={() =>
                                    handleWebcameClick(
                                      INSURANCE_BACK,
                                      PATIENT_DOCUMENT_TYPE.INSURANCE_BACK
                                    )
                                  }
                                >
                                  {" "}
                                  <i
                                    className="fa fa-camera "
                                    style={{ fontSize: "30px" }}
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-scrollable">
                            <table
                              className="table table-hover table-checkable order-column full-width"
                              id="example4"
                            >
                              <thead>
                                <tr>
                                  <th>{NO}</th>
                                  <th>{FILE_NAME}</th>
                                  <th>{THUMBNAIL}</th>
                                  <th>{CREATED_DATE}</th>
                                  <th>{ACTION}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!insuranceB ? (
                                  <>{<NoDataFound />}</>
                                ) : (
                                  insuranceB &&
                                  insuranceB.map((item, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                        <td>
                                          <div
                                            data-toggle="tooltip"
                                            title={item.fileName}
                                          >
                                            {item.fileName
                                              ? item.fileName.length > 20
                                                ? item.fileName.slice(0, 20) +
                                                  "..."
                                                : item.fileName
                                              : INSURANCE_BACK + ".png"}
                                          </div>
                                        </td>
                                        <td>
                                          {item.image
                                            .split(".")
                                            .at(-1)
                                            .toLowerCase() == "pdf" ? (
                                            <button
                                              type="button"
                                              className="btn btn-outline-danger"
                                              onClick={() =>
                                                window.open(
                                                  item.image,
                                                  "_blank"
                                                )
                                              }
                                            >
                                              <i className="fa  fa-file-pdf-o fa-2x"></i>
                                            </button>
                                          ) : (
                                            <Zoom>
                                              <img
                                                src={item.image}
                                                width="100"
                                                height="100"
                                                alt="Patient Document"
                                                style={{
                                                  height: "50px",
                                                  width: "50px",
                                                }}
                                              />
                                            </Zoom>
                                          )}{" "}
                                        </td>
                                        <td>
                                          {item.created_at
                                            ? dateFormateMonthDateYear(
                                                item.created_at
                                              )
                                            : "-"}
                                        </td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-xs"
                                            onClick={() =>
                                              handleEdit(
                                                INSURANCE_BACK,
                                                item.id,
                                                item.type
                                              )
                                            }
                                          >
                                            <i className="fa  fa-pencil"></i>
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-danger btn-xs"
                                            onClick={() =>
                                              handleDelete(
                                                item.id,
                                                item.type,
                                                patientId
                                              )
                                            }
                                          >
                                            <i className="fa  fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="d-flex justify-content-between my-2 mx-2">
                            <div>
                              <p
                                style={{
                                  fontSize: "Larger",
                                  fontWeight: "600",
                                }}
                              >
                                {XRAY}
                              </p>
                            </div>
                            <div className="d-flex justify-content-center">
                              <div>
                                <button
                                  className="btn btn-info mx-2"
                                  style={{
                                    borderRadius: "50%",
                                    width: "35px",
                                    height: "35px",
                                  }}
                                  onClick={() =>
                                    handleClick(
                                      XRAY,
                                      PATIENT_DOCUMENT_TYPE.X_RAY
                                    )
                                  }
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ marginLeft: "-1px" }}
                                  ></i>{" "}
                                </button>
                              </div>
                              <div style={{ marginTop: "10px" }}>
                                <button
                                  onClick={() =>
                                    handleWebcameClick(
                                      XRAY,
                                      PATIENT_DOCUMENT_TYPE.X_RAY
                                    )
                                  }
                                >
                                  {" "}
                                  <i
                                    className="fa fa-camera "
                                    style={{ fontSize: "30px" }}
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-scrollable">
                            <table
                              className="table table-hover table-checkable order-column full-width"
                              id="example4"
                            >
                              <thead>
                                <tr>
                                  <th>{NO}</th>
                                  <th>{FILE_NAME}</th>
                                  <th>{THUMBNAIL}</th>
                                  <th>{CREATED_DATE}</th>
                                  <th>{ACTION}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!imageX ? (
                                  <>{<NoDataFound />}</>
                                ) : (
                                  imageX &&
                                  imageX.map((item, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                        <td>
                                          <div
                                            data-toggle="tooltip"
                                            title={item.fileName}
                                          >
                                            {item.fileName
                                              ? item.fileName.length > 20
                                                ? item.fileName.slice(0, 20) +
                                                  "..."
                                                : item.fileName
                                              : XRAY + ".png"}
                                          </div>
                                        </td>
                                        <td>
                                          {item.image
                                            .split(".")
                                            .at(-1)
                                            .toLowerCase() == "pdf" ? (
                                            <button
                                              type="button"
                                              className="btn btn-outline-danger"
                                              onClick={() =>
                                                window.open(
                                                  item.image,
                                                  "_blank"
                                                )
                                              }
                                            >
                                              <i className="fa  fa-file-pdf-o fa-2x"></i>
                                            </button>
                                          ) : (
                                            <Zoom>
                                              <img
                                                src={item.image}
                                                width="100"
                                                height="100"
                                                alt="Patient Document"
                                                style={{
                                                  height: "50px",
                                                  width: "50px",
                                                }}
                                              />
                                            </Zoom>
                                          )}{" "}
                                        </td>
                                        <td>
                                          {item.created_at
                                            ? dateFormateMonthDateYear(
                                                item.created_at
                                              )
                                            : "-"}
                                        </td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-xs"
                                            onClick={() =>
                                              handleEdit(
                                                XRAY,
                                                item.id,
                                                item.type
                                              )
                                            }
                                          >
                                            <i className="fa  fa-pencil"></i>
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-danger btn-xs"
                                            onClick={() =>
                                              handleDelete(
                                                item.id,
                                                item.type,
                                                patientId
                                              )
                                            }
                                          >
                                            <i className="fa  fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="d-flex justify-content-between my-2 mx-2">
                            <div>
                              <p
                                style={{
                                  fontSize: "Larger",
                                  fontWeight: "600",
                                }}
                              >
                                {NOTE}
                              </p>
                            </div>
                            <div className="d-flex justify-content-center">
                              <div>
                                <button
                                  className="btn btn-info mx-2"
                                  style={{
                                    borderRadius: "50%",
                                    width: "35px",
                                    height: "35px",
                                  }}
                                  onClick={() =>
                                    handleClick(
                                      NOTE,
                                      PATIENT_DOCUMENT_TYPE.NOTE
                                    )
                                  }
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ marginLeft: "-1px" }}
                                  ></i>{" "}
                                </button>
                              </div>
                              <div style={{ marginTop: "10px" }}>
                                <button
                                  onClick={() =>
                                    handleWebcameClick(
                                      NOTE,
                                      PATIENT_DOCUMENT_TYPE.NOTE
                                    )
                                  }
                                >
                                  {" "}
                                  <i
                                    className="fa fa-camera "
                                    style={{ fontSize: "30px" }}
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-scrollable">
                            <table
                              className="table table-hover table-checkable order-column full-width"
                              id="example4"
                            >
                              <thead>
                                <tr>
                                  <th>{NO}</th>
                                  <th>{FILE_NAME}</th>
                                  <th>{THUMBNAIL}</th>
                                  <th>{CREATED_DATE}</th>
                                  <th>{ACTION}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!imageN ? (
                                  <>{<NoDataFound />}</>
                                ) : (
                                  imageN &&
                                  imageN.map((item, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                        <td>
                                          <div
                                            data-toggle="tooltip"
                                            title={item.fileName}
                                          >
                                            {item.fileName
                                              ? item.fileName.length > 20
                                                ? item.fileName.slice(0, 20) +
                                                  "..."
                                                : item.fileName
                                              : NOTE + ".png"}
                                          </div>
                                        </td>
                                        <td>
                                          {item.image
                                            .split(".")
                                            .at(-1)
                                            .toLowerCase() == "pdf" ? (
                                            <button
                                              type="button"
                                              className="btn btn-outline-danger"
                                              onClick={() =>
                                                window.open(
                                                  item.image,
                                                  "_blank"
                                                )
                                              }
                                            >
                                              <i className="fa  fa-file-pdf-o fa-2x"></i>
                                            </button>
                                          ) : (
                                            <Zoom>
                                              <img
                                                src={item.image}
                                                width="100"
                                                height="100"
                                                alt="Patient Document"
                                                style={{
                                                  height: "50px",
                                                  width: "50px",
                                                }}
                                              />
                                            </Zoom>
                                          )}{" "}
                                        </td>
                                        <td>
                                          {item.created_at
                                            ? dateFormateMonthDateYear(
                                                item.created_at
                                              )
                                            : "-"}
                                        </td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-xs"
                                            onClick={() =>
                                              handleEdit(
                                                NOTE,
                                                item.id,
                                                item.type
                                              )
                                            }
                                          >
                                            <i className="fa  fa-pencil"></i>
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-danger btn-xs"
                                            onClick={() =>
                                              handleDelete(
                                                item.id,
                                                item.type,
                                                patientId
                                              )
                                            }
                                          >
                                            <i className="fa  fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="d-flex justify-content-between my-2 mx-2">
                            <div>
                              <p
                                style={{
                                  fontSize: "Larger",
                                  fontWeight: "600",
                                }}
                              >
                                {ID}
                              </p>
                            </div>
                            <div className="d-flex justify-content-center">
                              <div>
                                <button
                                  className="btn btn-info mx-2"
                                  style={{
                                    borderRadius: "50%",
                                    width: "35px",
                                    height: "35px",
                                  }}
                                  onClick={() =>
                                    handleClick(ID, PATIENT_DOCUMENT_TYPE.ID)
                                  }
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ marginLeft: "-1px" }}
                                  ></i>{" "}
                                </button>
                              </div>
                              <div style={{ marginTop: "10px" }}>
                                <button
                                  onClick={() =>
                                    handleWebcameClick(
                                      ID,
                                      PATIENT_DOCUMENT_TYPE.ID
                                    )
                                  }
                                >
                                  {" "}
                                  <i
                                    className="fa fa-camera "
                                    style={{ fontSize: "30px" }}
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-scrollable">
                            <table
                              className="table table-hover table-checkable order-column full-width"
                              id="example4"
                            >
                              <thead>
                                <tr>
                                  <th>{NO}</th>
                                  <th>{FILE_NAME}</th>
                                  <th>{THUMBNAIL}</th>
                                  <th>{CREATED_DATE}</th>
                                  <th>{ACTION}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!imageId ? (
                                  <>{<NoDataFound />}</>
                                ) : (
                                  imageId &&
                                  imageId.map((item, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                        <td>
                                          <div
                                            data-toggle="tooltip"
                                            title={item.fileName}
                                          >
                                            {item.fileName
                                              ? item.fileName.length > 20
                                                ? item.fileName.slice(0, 20) +
                                                  "..."
                                                : item.fileName
                                              : ID + ".png"}
                                          </div>
                                        </td>
                                        <td>
                                          {item.image
                                            .split(".")
                                            .at(-1)
                                            .toLowerCase() == "pdf" ? (
                                            <button
                                              type="button"
                                              className="btn btn-outline-danger"
                                              onClick={() =>
                                                window.open(
                                                  item.image,
                                                  "_blank"
                                                )
                                              }
                                            >
                                              <i className="fa  fa-file-pdf-o fa-2x"></i>
                                            </button>
                                          ) : (
                                            <Zoom>
                                              <img
                                                src={item.image}
                                                width="100"
                                                height="100"
                                                alt="Patient Document"
                                                style={{
                                                  height: "50px",
                                                  width: "50px",
                                                }}
                                              />
                                            </Zoom>
                                          )}{" "}
                                        </td>
                                        <td>
                                          {item.created_at
                                            ? dateFormateMonthDateYear(
                                                item.created_at
                                              )
                                            : "-"}
                                        </td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-xs"
                                            onClick={() =>
                                              handleEdit(ID, item.id, item.type)
                                            }
                                          >
                                            <i className="fa  fa-pencil"></i>
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-danger btn-xs"
                                            onClick={() =>
                                              handleDelete(
                                                item.id,
                                                item.type,
                                                patientId
                                              )
                                            }
                                          >
                                            <i className="fa  fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="form-actions mt-5">
                        <div className="center">
                          <button
                            type="button"
                            className="mdl-js-button mdl-button--raised mdl-js-ripple-effect m-10 btn-success p-2"
                            onClick={btnFinish}
                          >
                            {FINISH}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EligibiltyCheck;
