//////////////////////////// AUTH ///////

export const DOCTOR_REGISTER = "api/v1/register";
export const VERIFY_CHANGE_PASSWORD_EMAIL = "api/v1/forgotPass";
export const CONFIRM_RESET_PASSWORD = "api/v1/setNewPass";
export const LOGOUT_API = "api/v1/logout";
export const LOGIN_API = "api/v1/login";
export const VERIFY_DOCUMENT = "api/v1/verify";

//////////////////////////// DOCTOR //////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////// DASHBOARD ///////

export const DOCTOR_DASHBOARD_API = "api/v1/doctor/dashboard";
export const DOCTOR_NOTICE_API = "api/v1/doctor/notice/list";

//////////////////////////// REGISTER ///////
export const PAYER_LIST_WITHOUT_MIDDLEWARE_API = "api/v1/payer/list";

export const FIND_DOCTOR_AFTER_LOGIN = "api/v1/doctor/office/doctor";
export const DOCTOR_ANOTHER_LIST_AFTER_LOGIN = "api/v1/doctor/practice/another/aferlogin/list";

export const PRACTICE_LIST_API = "api/v1/doctor/practice/another/list";
export const PRACTICE_SHOW_LIST = "api/v1/doctor/practice/add/list";
export const DOCTOR_SELECT_ADD = "api/v1/doctor/practice/addwithid";
export const REQUEST_DATA_DOCTOR_ADD = "api/v1/doctor/practice/request-data/addwithid";
export const DOCTOR_CREATE_ADD = "api/v1/doctor/practice/add";
export const REQUEST_DATA_DOCTOR_CREATE = "api/v1/doctor/practice/request-data/add";
export const FINAL_STEP = "api/v1/doctor/change/step";
export const OFFICE_TABLE_LIST = "api/v1/doctor/office/add/list";
export const DOCTOR_OFFICE_ADD = "api/v1/doctor/office/add";
export const OFFICE_REQEST_DATA_API = "api/v1/doctor/office/request-data/add";
export const MANAGE_OFFICE_LIST_API = "api/v1/doctor/office/addWithStatus/list";
export const ALL_STATUS_DOCTOR_LIST_API = "api/v1/doctor/office/alldoctorWithStatus/list";

export const OFFICE_ANOTHER_LIST = "api/v1/doctor/office/another/list";
export const REGISTER_OFFICE_DELETE = "api/v1/doctor/office/add/delete";
export const REGISTER_DOCTOR_DELETE = "api/v1/doctor/add/delete";

export const MANAGE_DOCTOR_API = "api/v1/admin/doctor/list";
export const ALL_DOCTOR_IN_SELECTED_OFFICE = "api/v1/doctor/office/alldoctor/list";
export const MANAGE_DOCTOR_NOTES_API = "api/v1/admin/doctor/listWithNotes";

////////////////////////// DOCTOR-PROFILE ///////////

export const PROFILE_API = "api/v1/doctor/profile";
export const EDIT_DOCTOR_PROFILE = "api/v1/doctor/profile";
export const EDIT_DOCTOR_PROFILE_PIC = "api/v1/doctor/profilepic";
export const CHANGE_PASSWORD_API = "api/v1/doctor/profile/change/password";
export const MANUAL_RESET_API = "api/v1/doctor/profile/manual/resetpassword";

////////////////////////// OFFICE-PROFILE ///////////

export const OFFICE_DETAIL_API = "api/v1/doctor/office";
export const EDIT_OFFICE_DETAIL_API = "api/v1/doctor/office/edit";
export const DIAGNOSES_API = "api/v1/doctor/drop-down/diagnoses/list";

//////////////////////////// CLAIM ///////

export const PATIENT_LIST_API = "api/v1/doctor/drop-down/patient/list";
export const PAYER_LIST_API = "api/v1/doctor/drop-down/payer/list";
export const PROCEDURE_LIST_API = "api/v1/doctor/procedure/list";
export const ADD_CLAIM_API = "api/v1/doctor/claim/add";
export const EDIT_CLAIM_API = "api/v1/doctor/claim/edit";
export const SUBMIT_CLAIM_API = "api/v1/doctor/claim/submit";
export const UPLOAD_DOCUMENT = "api/v1/doctor/claim/document/add";
export const CLAIM_LIST_API = "api/v1/doctor/claim/list";
export const CLAIM_DETAIL = "api/v1/doctor/claim";
export const CLAIM_DOCUMENT_LIST = "api/v1/doctor/claim/document/list";
export const UPDATE_ISSENTNOTE_CLAIM_API = "api/v1/doctor/claim/update/issentnote";

////////////////////////// PATIENT-PROFILE ///////////
export const ELIGIBILITY_HISTORY_CHECK_API = "api/v1/doctor/eligiblity-check/history/create";
export const ELIGIBILITY_HISTORY_CHECK_LIST_API = "api/v1/doctor/eligiblity-check/history/list";
export const PATIENT_PROFILE = "api/v1/doctor/patient";
export const MANAGE_PATIENT_API = "api/v1/doctor/patient/list";
export const DELETE_PATIENT_API = "api/v1/doctor/patient";
export const ELIGIBLE_PATIENT_ADD = "api/v1/doctor/patient/add";
export const PATIENT_ADD_WITHOUT_ELIGIBILITY = "api/v1/doctor/patient/withouteligibility/add";
export const PATIENT_DETAIL_WITHOUT_ELIGIBILITY = "api/v1/doctor/patient";
export const PATIENT_EDIT_WITHOUT_ELIGIBILITY = "api/v1/doctor/patient/edit";
export const UPLOAD_PATIENT_DOCUMENT = "api/v1/doctor/patient/document/add";
export const CHECK_CLAIM_LIST_API = "api/v1/doctor/patient/claim/list";

export const PATIENT_DOCUMENT_LIST = "api/v1/doctor/patient/document/list";
export const DELETE_PATIENT_DOCUMENT = "api/v1/doctor/patient/document";
export const COPY_OFFICE_DOCTOR_API = "api/v1/doctor/office/patient/copy";
export const ADD_OFFICE_DOCTOR_IN_COPY_PATIENT_API = "api/v1/doctor/office/patient/verify";

////////////////////////// PATIENT-PROFILE ///////////
export const ADD_PRIVATE_INSURANCE_API = "api/v1/doctor/patient/add";
export const ORAL_HEALTH_LIST_API = "api/v1/doctor/oral-health/list";
export const ALLERGIES_HEALTH_LIST_API = "api/v1/doctor/allergies/list";
export const MEDICAL_HISTORY_LIST_API = "api/v1/doctor/medical-history/list";
export const TREATMENT_LIST_API = "api/v1/doctor/treatment/list";
export const MEDICATION_LIST_API = "api/v1/doctor/medication/list";

//////////////////////////// ADMIN  //////////////////////////////////////////////////////////////////////////////////////////////////////

export const GET_URL = "api/v1/admin/create-by";
export const OFFICE_LIST_WITHOUT_PAGINATION = "api/v1/doctor/eligiblity-add-patient/office/list";
export const ALREADY_ASSIGN_DOCTOR = "api/v1/admin/office/select/list";
export const ADD_ASSIGN_DOCTOR = "api/v1/admin/office/practice/addwithid";
export const DOCTOR_DROPDOWN_LIST = "api/v1/admin/doctor/drop-down/list";
export const ASSIGN_OFFICE_VIA_FORM = "api/v1/admin/office/practice/add";
export const ADMIN_OFFICE_ADD = "api/v1/admin/office/add";
export const ADMIN_DETAIL_OFFICE = "api/v1/admin/office";
export const ADMIN_EDIT_OFFICE = "api/v1/admin/office/edit";
export const EDIT_DOCTOR = "api/v1/admin/doctor";
export const ASSIGNED_DOCTOR_DETAIL = "api/v1/admin/office-doctor/detail";
export const EDIT_ASSIGNED_DOCTOR = "api/v1/admin/office-doctor/edit";
export const DOCTOR_DELETE = "api/v1/doctor/office-doctor";
export const DELETE_DOCTOR = "api/v1/admin/doctor";
export const OFFICE_LIST_API = "api/v1/admin/office/list";
export const ACTIVE_OFFICE_STATUS_API = "api/v1/admin/office/status";
export const DEACTIVE_OFFICE_STATUS_API = "api/v1/admin/office/status";
export const DELETE_OFFICE = "api/v1/admin/office/delete";
export const EDIT_DOCTOR_DETAILS_API = "api/v1/admin/doctor/edit";
export const OFFICE_PRACTICE_LIST_API = "api/v1/admin/doctor/practice/list";
export const DOCTORS_WITH_CLAIM_LIST_API = "api/v1/admin/claim/drop-down/doctor/list";
export const MAIN_DOCTOR_LIST_API = "api/v1/admin/doctor/drop-down/main-doctor/list";
export const CLAIM_SUBMITTED_DATE_API = "api/v1/doctor/database/date-time";

////////////////////////// ADMIN-DASHBOARD ///////////

export const ADMIN_DASHBOARD_API = "api/v1/admin/dashboard";
export const WEEKLY_CLAIM_LIST_API = "api/v1/admin/weekly/claim/list";
export const PENDING_DOCTOR_LIST_API = "api/v1/admin/doctor/pending/list";
export const PENDING_MAIN_DOCTOR_API = "api/v1/admin/doctor/pending/view";
export const APPROVE_DOCTOR_API = "api/v1/admin/doctor/approve";
export const REJECT_DOCTOR_API = "api/v1/admin/doctor/reject";

////////////////////////// ADMIN-DOCTOR-REGISTER ///////////

export const ADMIN_DOCTOR_REGISTER = "api/v1/admin/doctor/register";

////////////////////////// ADMIN-PROFILE ///////////

export const ADMIN_PROFILE = "api/v1/admin/profile";
export const CHANGE_ADMIN_PASSWORD_API = "api/v1/admin/profile/change/password";

////////////////////////// EMPLOYEE  //////////////////////////////////////////////////////////////////////////////////////////////////////

export const EMPLOYEE_PROFILE_API = "api/v1/admin/employee/profile";
export const MANAGE_EMPLOYEE = "api/v1/admin/employee/list";
export const MANAGE_EMPLOYEE_STATUS = "";
export const EMPLOYEE_ADD = "api/v1/admin/employee/add";

export const EDIT_EMPLOYEE = "api/v1/admin/employee";
export const UPDATE_EMPLOYEE = "api/v1/admin/employee/edit";
export const UPLOAD_AGREEMENT_API = "api/v1/admin/employee/document/edit";
export const EMPLOYEE_DELETE_API = "api/v1/admin/employee";
export const EMPLOYEE_PROFILE = "api/v1/admin/employee/profile";
export const EDIT_EMPLOYEE_PROFILE_PIC = "api/v1/admin/employee/profilepic";

////////////////////////// STATE & CITY  //////////////////////////////////////////////////////////////////////////////////////////////////////
export const STATE_LIST = "api/v1/admin/state/list";
export const STATE_LIST_WITHOUT_PAGINATION = "api/v1/state/list";
export const CITY_LIST_WITHOUT_PAGINATION = "api/v1/city/list";
export const STATE_ADD = "api/v1/admin/state/add";
export const STATE_EDIT = "api/v1/admin/state";
export const STATE_UPDATE = "api/v1/admin/state/edit";

// city
export const CITY_LIST = "api/v1/admin/city/list";
export const CITY_ADD = "api/v1/admin/city/add";
export const CITY_EDIT = "api/v1/admin/city";
export const CITY_UPDATE = "api/v1/admin/city/edit";

////////////////////////// SPECIALTY & CPTGROUP  //////////////////////////////////////////////////////////////////////////////////////////////////////
export const SPECIALTY_LIST = "api/v1/admin/specialty/list";
export const SPECIALTY_LIST_WITHOUT_PAGINATION = "api/v1/specialty/list";
export const CPTGROUP_LIST_WITHOUT_PAGINATION = "api/v1/cptgroup/list";
export const SPECIALTY_ADD = "api/v1/admin/specialty/add";
export const SPECIALTY_EDIT = "api/v1/admin/specialty";
export const SPECIALTY_UPDATE = "api/v1/admin/specialty/edit";

export const CPTGROUP_LIST = "api/v1/admin/cptgroup/list";
export const CPTGROUP_ADD = "api/v1/admin/cptgroup/add";
export const CPTGROUP_EDIT = "api/v1/admin/cptgroup";
export const CPTGROUP_UPDATE = "api/v1/admin/cptgroup/edit";

//forgot password
export const CHECK_PASS_LINK = "api/v1/checkPassLink";
export const SET_RESET_PASS = "api/v1/resetUserPass";

// Change request
export const PERMISSION_REQUEST_API = "api/v1/admin/request/edit";
export const MANAGE_REQUEST_API = "api/v1/admin/request/list";
export const CHECK_ELIGIBLITY = "api/v1/doctor/check/eligiblity";
export const ACCEPT_REQUEST_API = "api/v1/admin/request/acceptReq";

export const DIAGNOSES_LIST_API = "api/v1/admin/diagnoses/list";
export const ADD_DIAGNOSES = "api/v1/admin/diagnoses/add";
export const ADMIN_DETAIL_DIAGNOSES = "api/v1/admin/diagnoses";
export const ADMIN_EDIT_DIAGNOSES = "api/v1/admin/diagnoses/edit";

export const PROCEDURE_LIST_ADMIN_API = "api/v1/admin/procedure/list";
export const ADD_PROCEDURE = "api/v1/admin/procedure/add";
export const ADMIN_DETAIL_PROCEDURE = "api/v1/admin/procedure";
export const ADMIN_EDIT_PROCEDURE = "api/v1/admin/procedure/edit";

export const PAYER_LIST_ADMIN_API = "api/v1/admin/payer/list";
export const PAYER_ADD = "api/v1/admin/payer/add";
export const PAYER_EDIT = "api/v1/admin/payer";
export const PAYER_UPDATE = "api/v1/admin/payer/edit";
export const MANAGE_ADMIN_CLAIM = "api/v1/admin/claim/list";
export const ADMIN_VIEW_OFFICE = "api/v1/admin/office/view";

//

export const ADMIN_PATIENT_LIST_API = "api/v1/admin/drop-down/patient/list";

// MANAGE NOTICE
export const NOTICE_ADD = "api/v1/admin/notice/add";
export const NOTICE_DETAIL = "api/v1/admin/notice";
export const NOTICE_DOCTOR_DETAIL = "api/v1/doctor/notice";
export const MANAGE_NOTICE = "api/v1/admin/notice/list";
export const DELETE_NOTICE = "api/v1/admin/notice/delete";
export const UPLOAD_ATTACHMENT = "api/v1/admin/notice/attachment/edit";
export const NOTICE_EDIT = "api/v1/admin/notice/edit";
export const UPLOAD_ENDPOINT = "api/v1/admin/notice/upload";

///////////////////////////////////////////////////////////////// TRIAL

export const DELETE_TRIAL_API = "api/v1/admin/doctor/delete";

///////////////////////////////////////////////////////////////// OP NOTE

export const ADD_OP_NOTE_API = "api/v1/doctor/op-note/add";
export const EDIT_OP_NOTE_API = "api/v1/doctor/op-note/edit";
export const DETAIL_NOTE_API = "api/v1/doctor/op-note";
export const OP_NOTE_DOWNLOAD_API = "api/v1/doctor/op-note/pdf/download";

///////////////////////////////////////////////////////////////// EOB
export const UPLOAD_EOB_DOCUMENT = "api/v1/admin/EOB/document/upsert";
export const EOB_DOCUMENT_LIST_API = "api/v1/doctor/EOB/document/list";
export const EOB_DOCUMENT_DELETE_API = "api/v1/admin/EOB/document";

export const EOB_ADD_API = "api/v1/admin/EOB/upsert";
export const DETAIL_EOB_API = "api/v1/admin/EOB";
export const EOB_LIST_API = "api/v1/doctor/EOB/list";
export const EOB_OFFICE_LIST_API = "api/v1/admin/EOB/drop-drown/office/list";
export const EOB_OFFICE_SELECT_LIST_API = "api/v1/admin/EOB/drop-drown/select-office/list";
export const EOB_EXCEL_DOWNLOAD_API = "api/v1/doctor/EOB/excel/create";

///////////////////////////////////////////////////////////////// RECEIPT
export const RECEIPT_LIST_API = "api/v1/doctor/receipt/list";
export const RECEIPT_DOCUMENT_LIST_API = "api/v1/doctor/receipt/document/list";
export const RECEIPT_OFFICE_LIST_API = "api/v1/admin/receipt/drop-drown/office/list";
export const RECEIPT_OFFICE_SELECT_LIST_API = "api/v1/admin/receipt/drop-drown/select-office/list";
export const DETAIL_RECEIPT_API = "api/v1/admin/receipt";
export const UPLOAD_RECEIPT_DOCUMENT = "api/v1/admin/receipt/document/upsert";
export const RECEIPT_DOCUMENT_DELETE_API = "api/v1/admin/receipt/document";
export const RECEIPT_ADD_API = "api/v1/admin/receipt/upsert";
export const RECEIPT_EXCEL_DOWNLOAD_API = "api/v1/doctor/receipt/excel/create";

///////////////////////////////////////////////////////////////// PATIENT_CHART
export const PATIENT_TABS_API = "api/v1/doctor/patient-chart/tab/list";
export const PATIENT_TABS_CONTENT_API = "api/v1/doctor/patient-chart/tab/question/list";
export const PATIENT_DETAIL_ADD_API = "api/v1/doctor/patient-chart/tab/details/upsert";
export const DOWNLOAD_EXCEL_FILE = "DownloadExcel/demo.xlsx";

///////////////////////////////////////////////////////////////// ADMIN PATIENT_CHART
export const ADMIN_TAB_LIST_API = "api/v1/admin/patient-chart/tab/list";
export const ADMIN_DETAIL_PATIENT_TABS_API = "api/v1/admin/patient-chart/tab";
export const ADMIN_ADD_TABS_API = "api/v1/admin/patient-chart/tab/upsert";

export const ADMIN_QUESTION_LIST_API = "api/v1/admin/patient-chart/question/list";
export const ADMIN_QUESTION_DETAIL_API = "api/v1/admin/patient-chart/question";
export const ADMIN_ADD_QUESTION_API = "api/v1/admin/patient-chart/question/upsert";
export const ADMIN_CREATE_DEMO_EXCEL_FILE = "api/v1/admin/patient-chart/question/excel/create";
export const ADMIN_UPLOAD_QUESTION_EXCEL_FILE = "api/v1/admin/excel/upload";
export const ADMIN_INSERT_CSV_DATA = "api/v1/admin/csv/tab-group-question/add";
export const ADMIN_VALIDATE_CSV_DATA = "api/v1/admin/csv/tab-group-question/validate";

export const DELETE_DIAGNOSES_FROM_QUESTION_API = "api/v1/admin/patient-chart/question/diagnoses";
export const ADD_DIAGNOSES_FROM_QUESTION_API = "api/v1/admin/patient-chart/question/diagnoses/upsert";
export const DIAGNOSES_FROM_QUESTION_LIST_API = "api/v1/admin/patient-chart/question/diagnoses/list";

///////////////////////////////////////////////////////////////// ADMIN PATIENT_CHART_GROUP
export const ADMIN_GROUP_LIST_API = "api/v1/admin/patient-chart/tab-group/list";
export const ADMIN_DETAIL_PATIENT_GROUP_API = "api/v1/admin/patient-chart/tab-group";
export const ADMIN_ADD_GROUP_API = "api/v1/admin/patient-chart/tab-group/upsert";

///////////////////////////////////////////////////////////////// ROLE
export const ROLE_LIST_API = "api/v1/admin/role/list";
export const ROLE_ADD_API = "api/v1/admin/role/upsert";
export const ROLE_DETAIL_API = "api/v1/admin/role";
export const PAGE_ADD_API = "api/v1/admin/page/authority/upsert";
export const PAGE_DETAIL_API = "api/v1/admin/page/authority/list";

///////////////////////////////////////////////////////////////// COMPREHENSIVE_NOTE
export const COMPREHENSIVE_NOTE_DATA_API = "api/v1/doctor/patient-chart/question-diagnoses-description/list";
export const ADD_COMPREHENSIVE_NOTE = "api/v1/doctor/patient/comprehensive-note/upsert";
export const COMPREHENSIVE_NOTE_LIST_API = "api/v1/doctor/patient/comprehensive-note/list";
export const COMPREHENSIVE_NOTE_DETAIL_API = "api/v1/doctor/patient/comprehensive-note";
export const COMPREHENSIVE_NOTE_DIAGNOSES_PROCEDURE_API = "api/v1/doctor/patient/comprehensive-note/diagnoses-procedure/list";
export const CHECK_DUPLICATE_VALUE_IN_COMPREHENSIVE_NOTE_API = "api/v1/doctor/patient/comprehensive-note/verify";
export const COMPREHENSIVE_QUESTION_LIST_API = "api/v1/admin/patient-chart/comprehensive-note/question/list";

export const CN_GROUP_LIST_API = "api/v1/admin/comprehensive-note/group/list";
export const CN_GROUP_ADD_API = "api/v1/admin/comprehensive-note/group/upsert";
export const GROUP_DETAIL_API = "api/v1/admin/comprehensive-note/group";

export const CN_QUESTION_LIST_API = "api/v1/admin/comprehensive-note/description/list";
export const CN_QUESTION_DETAIL_API = "api/v1/admin/comprehensive-note/description";
export const CN_QUESTION_ADD_API = "api/v1/admin/comprehensive-note/description/upsert";

export const CN_JSON_DATA = "api/v1/doctor/comprehensive-note/group-description/json/list";
export const CN_DOWNLOAD_API = "api/v1/doctor/comprehensive-note/pdf/download";

export const MEDICATION_VALUE_LIST_API = "api/v1/doctor/patient-chart/question/medication/list";
export const ALLERGIES_VALUE_LIST_API = "api/v1/doctor/patient-chart/question/allergies/list";

///////////////////////////////////////////////////////////////// DOCUMENTS
export const UPLOAD_DOCUMENT_DOCUMENT = "api/v1/admin/develop/document/upsert";
export const ADMIN_UPLOAD_DOCUMENT_DOCUMENT = "api/v1/admin/develop/document";
export const DOCUMENT_LIST_API = "api/v1/doctor/develop/list";
export const DOCUMENT_DELETE_API = "api/v1/admin/develop/document";

export const DOCUMENT_ADD_API = "api/v1/admin/develop/upsert";
export const DETAIL_DOCUMENT_API = "api/v1/admin/documents";
export const UPLOAD_DOCUMENT_LIST_API = "api/v1/doctor/documents/document/list";
export const DEVELOP_DOCUMENT_DETAIL_API = "api/v1/doctor/develop";
export const ADMIN_DEVELOP_DOCUMENT_DETAIL_API = "api/v1/admin/develop";

///////////////////////////////////////////////////////////////// PRE_AUTHORIZATION
export const PRE_AUTHORIZATION_NOTE_DATA_API = "api/v1/doctor/patient-chart/question-diagnoses-description/list";
export const ADD_PRE_AUTHORIZATION_NOTE = "api/v1/doctor/patient/pre-authorization-note/upsert";
export const PRE_AUTHORIZATION_NOTE_LIST_API = "api/v1/doctor/patient/pre-authorization-note/list";
export const PRE_AUTHORIZATION_NOTE_DETAIL_API = "api/v1/doctor/patient/pre-authorization-note";
export const CHECK_DUPLICATE_VALUE_IN_PRE_AUTHORIZATION_NOTE_API = "api/v1/doctor/patient/pre-authorization-note/verify";
export const WEEKLY_PRE_AUTHORIZATION_LIST_API = "api/v1/admin/weekly/pre-authorization-note/list";
export const PRE_AUTHORIZATION_DOCTOR_LIST_API = "api/v1/admin/patient/pre-authorization-note/drop-down/doctor/list";
export const UPDATE_PRE_AUTHORIZATION_STATUS = "api/v1/admin/patient/pre-authorization-note/status/upsert";
export const CHANGED_FIELD_RESPONSE_API = "api/v1/doctor/patient/pre-authorization-note/changedfiled";
export const DETAIL_PRE_AUTHORIZATION_STATUS_API = "api/v1/doctor/patient/pre-authorization-note/status";
export const WEEKLY_PRE_AUTHORIZATION_DOCTOR_LIST_API = "api/v1/doctor/weekly/pre-authorization-note/list";
export const PRE_AUTHORIZATION_DOWNLOAD_API = "api/v1/doctor/pre-authorization-note/pdf/download";

///////////////////////////////////////////////////////////////// OFFICE_EMPLOYEE
export const OFFICE_EMPLOYEE_ADD = "api/v1/doctor/office/new-role/add";
export const OFFICE_EMPLOYEE_EDIT_API = "api/v1/doctor/office/new-role/update";
export const OFFICE_EMPLOYEE_LIST_API = "api/v1/doctor/office/new-role/list";
export const DETAIL_OFFICE_EMPLOYEE_API = "api/v1/doctor/office/new-role";
export const EDIT_OFFICE_ROLE_LIST_API = "api/v1/doctor/office/role/list";

export const OFFICE_EMPLOYEE_STATUS_CHANGE_API = "api/v1/admin/office/new-role/status/change";
