import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dateAPIFormate, dateFormateMonthDateYear } from "../../Helper/Helper";
import {
    FIRST_NAME,
    GENDER,
    LAST_NAME,
    ADDRESS,
    ADDRESS_COUNTINUED,
    STATE,
    ZIP,
    CITY,
    BIRTH_DATE,
    SOCIAL_SECURITY_NUMBER,
    IS_MEDICARE_ELIGIBLE,
    MEDICARE_ELIGIBILITY_STARTS_AT,
    MEDICARE_PATIENT_ID,
    PPO_ADVANTAGE_PLAN_PHONE,
    PPO_ADVANTAGE_PAYER_ID,
    PPO_ADVANTAGE_PAYER_ADDRESS,
    INSURANCE_FRONT,
    INSURANCE_BACK,
    XRAY,
    NOTE,
    OTHERS,
    IS_MEDICARE_ELIGIBLE_OPTION,
    SAVE,
    CANCEL,
    SELECT,
    SELECT_GENDER,
    HMO_ADVANTAGE_PLAN_PHONE,
    HMO_ADVANTAGE_PAYER_ADDRESS,
    HMO_ADVANTAGE_PAYER_ID,
    HMO_ADVANTAGE_PLAN_SPONSOR,
    HMO_ADVANTAGE_PLAN_NUMBER,
    PPO_ADVANTAGE_PLAN_SPONSOR,
    PPO_ADVANTAGE_PLAN_NUMBER,
    ID,
    NO,
    FILE_NAME,
    THUMBNAIL,
    ACTION,
    PATIENT_DOCUMENTS,
    PATIENT_DOCUMENT_TYPE,
    DATE_OF_LAST_EXAM,
    PHYSICIAN_NAME,
    WEIGHT,
    HEIGHT_IN_INCHIES,
    REASON_FOR_TOADAY_VISIT,
    GENERAL_HEALTH,
    GENERAL_HEALTH_OPTIONS,
    CREATED_DATE,
} from "../../Helper/Constants";
import { baseURL } from "../../Config";
import axios from "axios";
import Swal from "sweetalert2";

import "react-datepicker/dist/react-datepicker.css";
import {
    PATIENT_DETAIL_WITHOUT_ELIGIBILITY,
    PATIENT_EDIT_WITHOUT_ELIGIBILITY,
    PATIENT_DOCUMENT_LIST,
    STATE_LIST_WITHOUT_PAGINATION,
    CITY_LIST_WITHOUT_PAGINATION,
    DELETE_PATIENT_DOCUMENT,
} from "../../Helper/ApiPath";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DELETE_IMAGE_MESSAGE, ENTER_ADDRESS, ENTER_CITY, ENTER_FNAME, ENTER_LNAME, ENTER_STATE, ENTER_ZIP } from "../../Helper/Messages";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import NoDataFound from "../CommonComponent/NoDataFound";
import UploadImage from "./Patients/UploadImage";
import Zoom from "react-medium-image-zoom";
import WebcamUpload from "./Patients/WebcamUpload";

function EditPatient() {
    const [isLoading, setIsLoading] = useState(false);
    const [city, setCity] = useState({
        stateId: "",
        cityId: "",
    });

    const handleCityChange = (e) => {
        setCity({ ...city, cityId: e.value });
    };

    let doctorToken = JSON.parse(localStorage.getItem("DoctorToken"));
    let doctorPracticeId = localStorage.getItem("doctorPracticeId");
    let patientId = localStorage.getItem("patientId");

    const navigate = useNavigate();

    const [patientList, setPatientList] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        address1: "",
        address2: "",
        zip: "",
        dob: "",
        gender: "",
        socialSecurityNo: "",
        height: "",
        weight: "",
        physicianName: "",
        dateOfLastExam: "",

        medicareEligible: "",
        madicareEligibilityStartAt: "",
        MedicarePatientId: "",

        PPOInsuranceName: "",
        PPOInsuranceNumber: "",
        PPOPayerId: "",
        PPOPayerAddress: "",
        PPOPhone: "",

        HMOInsuranceName: "",
        HMOInsuranceNumber: "",
        HMOPayerId: "",
        HMOPayerAddress: "",
        HMOPhone: "",

        ReasonForTodayVisit: "",
        GeneralHealth: "",
    });
    const [insuranceF, setInsuranceF] = useState([]);
    const [insuranceB, setInsuranceB] = useState([]);
    const [imageX, setImageX] = useState([]);
    const [imageN, setImageN] = useState([]);
    const [imageId, setImageId] = useState([]);
    const [imageOt, setImageOt] = useState([]);
    const [popUpTitle, setPopUpTitle] = useState("");
    const [imageType, setImageType] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [show, setShow] = useState(false);
    const [webcame, setWebcame] = useState(false);
    const [edit, setEdit] = useState(false);
    let [image, setImage] = useState({ preview: "", data: "" });

    const handleImageChange = (e) => {
        const data = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
            name: e.target.files[0].name,
        };

        const fileExtension = data.data.name.split(".").at(-1).toLowerCase();

        let allowedFileTypes =
            imageType == PATIENT_DOCUMENT_TYPE.NOTE ||
            imageType == PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT ||
            imageType == PATIENT_DOCUMENT_TYPE.INSURANCE_BACK ||
            imageType == PATIENT_DOCUMENT_TYPE.X_RAY ||
            imageType == PATIENT_DOCUMENT_TYPE.ID ||
            imageType == PATIENT_DOCUMENT_TYPE.OTHERS
                ? ["pdf", "jpg", "png", "gif", "jpeg"]
                : ["jpg", "png", "gif", "jpeg"];

        if (!allowedFileTypes.includes(fileExtension)) {
            e.target.value = null;
            alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
        } else {
            let element = document.getElementById("button");
            element.classList.remove("d-none");
            setImage(data);
        }
    };
    const fetchThumbnailData = async (type, id) => {
        axios
            .post(`${baseURL}/${PATIENT_DOCUMENT_LIST}`, {
                headers: { authorization: doctorToken },
                type: type,
                patientId: id,
            })
            .then((res) => {
                if (type == PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT) {
                    setInsuranceF(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.INSURANCE_BACK) {
                    setInsuranceB(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.X_RAY) {
                    setImageX(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.NOTE) {
                    setImageN(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.ID) {
                    setImageId(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.OTHERS) {
                    setImageOt(res.data.data);
                }
            });
    };

    const handleEdit = (title, id, type) => {
        setShow(true);
        setEdit(true);
        setPopUpTitle(title);
        setDocumentId(id);
        setImageType(type);
    };

    const handleClick = (title, type) => {
        setShow(true);
        setPopUpTitle(title);
        setImageType(type);
    };

    const handleClose = () => {
        setShow(false);
        setEdit(false);
    };
    const handleWebcameClick = (title, type) => {
        setWebcame(true);
        setPopUpTitle(title);
        setImageType(type);
    };

    const handleWebcameClose = () => {
        setWebcame(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPatientList({ ...patientList, [name]: value });
    };

    useEffect(async () => {
        const patientId = localStorage.getItem("patientId");
        let token = JSON.parse(localStorage.getItem("logToken"));
        await axios
            .get(`${baseURL}/${PATIENT_DETAIL_WITHOUT_ELIGIBILITY}/${patientId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setPatientList(res.data.data);
                setCity(res.data.data);
                setStateId(res.data.data.stateId);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    }, []);

    const handleDelete = (id, type) => {
        Swal.fire({
            title: DELETE_IMAGE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${DELETE_PATIENT_DOCUMENT}/${id}`, {
                            headers: { authorization: doctorToken },
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");
                            fetchThumbnailData(type, patientId);
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!patientList.firstName) {
            toast.error(ENTER_FNAME);
        } else if (!patientList.lastName) {
            toast.error(ENTER_LNAME);
        } else if (!patientList.address1) {
            toast.error(ENTER_ADDRESS);
        } else if (!city.stateId) {
            toast.error(ENTER_STATE);
        } else if (!city.cityId) {
            toast.error(ENTER_CITY);
        } else if (!patientList.zip) {
            toast.error(ENTER_ZIP);
        } else if (!patientList.dob) {
            toast.error("Please Enter Date of Birth..");
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${PATIENT_EDIT_WITHOUT_ELIGIBILITY}`, {
                    headers: { authorization: doctorToken },
                    practiceId: doctorPracticeId,
                    patientId: patientId,
                    firstName: patientList.firstName,
                    middleName: patientList.middleName,
                    lastName: patientList.lastName,
                    gender: patientList.gender,
                    dob: dateAPIFormate(patientList.dob),
                    address1: patientList.address1,
                    address2: patientList.address2,
                    state: city.stateId,
                    city: city.cityId,
                    zip: patientList.zip,
                    socialSecurityNo: patientList.socialSecurityNo,

                    height: patientList.height,
                    weight: patientList.weight,
                    physicianName: patientList.physicianName,
                    dateOfLastExam: dateAPIFormate(patientList.dateOfLastExam),

                    madicareEligibilityStartAt: dateAPIFormate(patientList.madicareEligibilityStartAt),
                    MedicarePatientId: patientList.MedicarePatientId,

                    PPOInsuranceName: patientList.PPOInsuranceName,
                    PPOInsuranceNumber: patientList.PPOInsuranceNumber,
                    PPOPayerId: patientList.PPOPayerId,
                    PPOPayerAddress: patientList.PPOPayerAddress,
                    PPOPhone: patientList.PPOPhone,

                    HMOInsuranceName: patientList.PPOInsuranceName,
                    HMOInsuranceNumber: patientList.PPOInsuranceNumber,
                    HMOPayerId: patientList.PPOPayerId,
                    HMOPayerAddress: patientList.PPOPayerAddress,
                    HMOPhone: patientList.PPOPhone,

                    ReasonForTodayVisit: patientList.ReasonForTodayVisit,
                    GeneralHealth: patientList.GeneralHealth,
                })
                .then((res) => {
                    setIsLoading(false);
                    toast.success(res.data.message);
                    setPatientList("");
                    setCity("");
                    setCityList("");

                    navigate("/patientChart");
                    window.location.reload();
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const btnCancel = () => {
        navigate("/managePatient");
    };

    ////////////////////////////////////////////////////// STATE-CITY LIST /////////////////////
    const [stateList, setStateList] = useState([]);

    const stateData = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const [cityList, setCityList] = useState([]);
    const [stateId, setStateId] = useState([]);

    const handleStateChange = (e) => {
        setCity({ ...city, stateId: e.value, cityId: "" });
        setCityList("");
        cityData();
        setStateId(e.value);
    };

    const cityData = async () => {
        axios
            .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
                stateId: stateId,
                search: "",
            })
            .then((res) => {
                setCityList(res.data.data);
            });
    };

    const stateOptions =
        stateList &&
        stateList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const cityOptions =
        cityList &&
        cityList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    useEffect(() => {
        stateData();
        cityData();
    }, [stateId]);

    useEffect(() => {
        fetchThumbnailData(PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT, patientId);
        fetchThumbnailData(PATIENT_DOCUMENT_TYPE.INSURANCE_BACK, patientId);
        fetchThumbnailData(PATIENT_DOCUMENT_TYPE.X_RAY, patientId);
        fetchThumbnailData(PATIENT_DOCUMENT_TYPE.NOTE, patientId);
        fetchThumbnailData(PATIENT_DOCUMENT_TYPE.ID, patientId);
        fetchThumbnailData(PATIENT_DOCUMENT_TYPE.OTHERS, patientId);
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <div style={{ display: "flex" }} id="formData">
                        <div className="card-body" id="bar-parent">
                            <form action="#" id="form_sample_1" className="form-horizontal">
                                <div className="form-body">
                                    <div className="form-group row">
                                        <label className="control-label col-md-4">
                                            {FIRST_NAME}
                                            <span className="required"> * </span>
                                        </label>
                                        <div className="col-md-7">
                                            <input
                                                name="firstName"
                                                value={patientList && patientList.firstName}
                                                type="text"
                                                placeholder="Enter First Name"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-4">
                                            {LAST_NAME}
                                            <span className="required"> * </span>
                                        </label>
                                        <div className="col-md-7">
                                            <input
                                                name="lastName"
                                                value={patientList && patientList.lastName}
                                                type="text"
                                                placeholder="Enter Last Name"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-4">
                                            {ADDRESS}
                                            <span className="required"> * </span>
                                        </label>
                                        <div className="col-md-7">
                                            <input
                                                name="address1"
                                                value={patientList && patientList.address1}
                                                type="text"
                                                placeholder="Enter Address"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-4">{ADDRESS_COUNTINUED} </label>
                                        <div className="col-md-7">
                                            <input
                                                name="address2"
                                                value={patientList && patientList.address2}
                                                type="text"
                                                placeholder="Enter Address"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-4">
                                            {STATE}
                                            <span className="required"> * </span>
                                        </label>
                                        <div className="col-md-7">
                                            <Select
                                                name="stateId"
                                                value={stateOptions && stateOptions.filter(({ value }) => value == city.stateId)}
                                                options={stateOptions}
                                                onChange={handleStateChange}
                                                placeholder={SELECT}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-4">
                                            {CITY}
                                            <span className="required"> * </span>
                                        </label>

                                        <div className="col-md-7">
                                            <Select
                                                name="cityId"
                                                value={cityOptions && cityOptions.filter(({ value }) => value == city.cityId)}
                                                options={cityOptions}
                                                onChange={handleCityChange}
                                                placeholder={SELECT}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-md-4">
                                            {ZIP}
                                            <span className="required"> * </span>
                                        </label>
                                        <div className="col-md-7">
                                            <input
                                                name="zip"
                                                value={patientList && patientList.zip}
                                                type="text"
                                                placeholder="Enter Zip Code"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-4">
                                            {BIRTH_DATE}
                                            <span className="required"> * </span>
                                        </label>
                                        <div className="col-md-7">
                                            <DatePicker
                                                className="form-control mr-sm-2"
                                                value={dateFormateMonthDateYear(patientList && patientList.dob)}
                                                maxDate={new Date()}
                                                scrollableMonthDropdown={true}
                                                showYearDropdown
                                                showMonthDropdown
                                                onChange={(date) => setPatientList({ ...patientList, dob: date })}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-4">{GENDER}</label>
                                        <div className="col-md-7">
                                            <select className="form-control input-height" name="gender" value={patientList.gender} onChange={handleChange}>
                                                <option value=""> {SELECT_GENDER}</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-4">{SOCIAL_SECURITY_NUMBER}</label>
                                        <div className="col-md-7">
                                            <input
                                                name="socialSecurityNo"
                                                value={patientList && patientList.socialSecurityNo}
                                                type="number"
                                                placeholder="Enter Social Security Number"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-4">{HEIGHT_IN_INCHIES}</label>
                                        <div className="col-md-7">
                                            <input
                                                name="height"
                                                value={patientList && patientList.height}
                                                type="number"
                                                placeholder="Enter Height In Inchies"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-md-4">{WEIGHT}</label>
                                        <div className="col-md-7">
                                            <input
                                                name="weight"
                                                value={patientList && patientList.weight}
                                                type="number"
                                                placeholder="Enter Weight"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-md-4">{PHYSICIAN_NAME}</label>
                                        <div className="col-md-7">
                                            <input
                                                name="physicianName"
                                                value={patientList && patientList.physicianName}
                                                type="text"
                                                placeholder="Enter Physician Name"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-md-4">{DATE_OF_LAST_EXAM}</label>
                                        <div className="col-md-7">
                                            <DatePicker
                                                className="form-control input-height mr-sm-2"
                                                placeholderText={"Please Select Date"}
                                                maxDate={new Date()}
                                                scrollableMonthDropdown={true}
                                                showYearDropdown
                                                showMonthDropdown
                                                value={dateFormateMonthDateYear(patientList && patientList.dateOfLastExam)}
                                                onChange={(date) => setPatientList({ ...patientList, dateOfLastExam: date })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="card-body" id="bar-parent">
                            <form action="#" id="form_sample_1" className="form-horizontal">
                                <div className="form-body">
                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{IS_MEDICARE_ELIGIBLE} </label>
                                        <div className="col-md-6">
                                            <select className="form-control input-height" name="isMedicareEligible" value={patientList.isMedicareEligible} onChange={handleChange}>
                                                {Object.keys(IS_MEDICARE_ELIGIBLE_OPTION).map((item, i) => {
                                                    return (
                                                        <>
                                                            <option key={i} value={IS_MEDICARE_ELIGIBLE_OPTION[item]}>
                                                                {item}
                                                            </option>
                                                        </>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{MEDICARE_ELIGIBILITY_STARTS_AT}</label>
                                        <div className="col-md-6">
                                            <DatePicker
                                                className="form-control mr-sm-2"
                                                maxDate={new Date()}
                                                scrollableMonthDropdown={true}
                                                showYearDropdown
                                                showMonthDropdown
                                                value={dateFormateMonthDateYear(patientList && patientList.madicareEligibilityStartAt)}
                                                onChange={(date) => setPatientList({ ...patientList, madicareEligibilityStartAt: date })}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{MEDICARE_PATIENT_ID}</label>
                                        <div className="col-md-6">
                                            <input
                                                name="MedicarePatientId"
                                                value={patientList && patientList.MedicarePatientId}
                                                type="text"
                                                placeholder="Enter Medicare Patient ID"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{PPO_ADVANTAGE_PLAN_NUMBER} </label>
                                        <div className="col-md-6">
                                            <input
                                                name="PPOInsuranceNumber"
                                                value={patientList && patientList.PPOInsuranceNumber}
                                                type="text"
                                                placeholder="Enter PPO/Advantage Plan Number"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{PPO_ADVANTAGE_PLAN_SPONSOR} </label>
                                        <div className="col-md-6">
                                            <input
                                                name="PPOInsuranceName"
                                                value={patientList && patientList.PPOInsuranceName}
                                                type="text"
                                                placeholder="Enter PPO/Advantage Plan Sponsor"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{PPO_ADVANTAGE_PAYER_ID} </label>
                                        <div className="col-md-6">
                                            <input
                                                name="PPOPayerId"
                                                value={patientList && patientList.PPOPayerId}
                                                type="text"
                                                placeholder="Enter PPO/Advantage Plan Payer"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{PPO_ADVANTAGE_PAYER_ADDRESS}</label>
                                        <div className="col-md-6">
                                            <input
                                                name="PPOPayerAddress"
                                                value={patientList && patientList.PPOPayerAddress}
                                                type="text"
                                                placeholder="Enter PPO/Advantage Address"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{PPO_ADVANTAGE_PLAN_PHONE} </label>
                                        <div className="col-md-6">
                                            <input
                                                name="PPOPhone"
                                                value={patientList && patientList.PPOPhone}
                                                type="text"
                                                placeholder="Enter PPO/Advantage Phone"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{HMO_ADVANTAGE_PLAN_NUMBER} </label>
                                        <div className="col-md-6">
                                            <input
                                                name="HMOInsuranceNumber"
                                                value={patientList && patientList.HMOInsuranceNumber}
                                                type="text"
                                                placeholder="Enter HMO Plan Number"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{HMO_ADVANTAGE_PLAN_SPONSOR} </label>
                                        <div className="col-md-6">
                                            <input
                                                name="HMOInsuranceName"
                                                value={patientList && patientList.HMOInsuranceName}
                                                type="text"
                                                placeholder="Enter HMO Plan Sponsor"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{HMO_ADVANTAGE_PAYER_ID}</label>
                                        <div className="col-md-6">
                                            <input
                                                name="HMOPayerId"
                                                value={patientList && patientList.HMOPayerId}
                                                type="text"
                                                placeholder="Enter HMO Plan Payer"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{HMO_ADVANTAGE_PAYER_ADDRESS}</label>
                                        <div className="col-md-6">
                                            <input
                                                name="HMOPayerAddress"
                                                value={patientList && patientList.HMOPayerAddress}
                                                type="text"
                                                placeholder="Enter HMO Address"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{HMO_ADVANTAGE_PLAN_PHONE} </label>
                                        <div className="col-md-6">
                                            <input
                                                name="HMOPhone"
                                                value={patientList && patientList.HMOPhone}
                                                type="text"
                                                placeholder="Enter HMO Phone"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{REASON_FOR_TOADAY_VISIT} </label>
                                        <div className="col-md-6">
                                            <input
                                                name="ReasonForTodayVisit"
                                                value={patientList && patientList.ReasonForTodayVisit}
                                                type="text"
                                                placeholder="Enter Reason For Today's Visit"
                                                className="form-control input-height"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-md-6">{GENERAL_HEALTH} </label>
                                        <div className="col-md-6">
                                            <select className="form-control input-height" name="GeneralHealth" value={patientList && patientList.GeneralHealth} onChange={handleChange}>
                                                <option value=""> {SELECT} </option>
                                                {Object.keys(GENERAL_HEALTH_OPTIONS).map((item, i) => {
                                                    return (
                                                        <option key={i} value={GENERAL_HEALTH_OPTIONS[item]}>
                                                            {item}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div id="imageSection">
                    <UploadImage
                        popUpTitle={popUpTitle}
                        show={show}
                        handleClose={handleClose}
                        patientId={patientId}
                        imageType={imageType}
                        edit={edit}
                        documentId={documentId}
                        handleImageChange={handleImageChange}
                        image={image}
                        fetchThumbnailData={() => fetchThumbnailData(imageType, patientId)}
                    />

                    <WebcamUpload
                        popUpTitle={popUpTitle}
                        webcame={webcame}
                        handleWebcameClose={handleWebcameClose}
                        patientId={patientId}
                        imageType={imageType}
                        fetchThumbnailData={() => fetchThumbnailData(imageType, patientId)}
                    />
                    <div className="card">
                        <div className="card-head">
                            <header>{PATIENT_DOCUMENTS}</header>
                        </div>
                        <div className="card-body no-padding height-9">
                            <div className="row mx-2">
                                <div className="col-6">
                                    <div className="d-flex justify-content-between my-2 mx-2">
                                        <div>
                                            <p style={{ fontSize: "Larger", fontWeight: "600" }}>{INSURANCE_FRONT}</p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div>
                                                <button
                                                    className="btn btn-info mx-2"
                                                    style={{ borderRadius: "50%", width: "35px", height: "35px" }}
                                                    onClick={() => handleClick(INSURANCE_FRONT, PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT)}
                                                >
                                                    <i className="fa fa-plus" style={{ marginLeft: "-1px" }}></i>{" "}
                                                </button>
                                            </div>
                                            <div style={{ marginTop: "10px" }}>
                                                <button onClick={() => handleWebcameClick(INSURANCE_FRONT, PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT)}>
                                                    {" "}
                                                    <i className="fa fa-camera " style={{ fontSize: "30px" }}></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-scrollable">
                                        <table className="table table-hover table-checkable order-column full-width" id="example4">
                                            <thead>
                                                <tr>
                                                    <th>{NO}</th>
                                                    <th>{FILE_NAME}</th>
                                                    <th>{THUMBNAIL}</th>
                                                    <th>{CREATED_DATE}</th>
                                                    <th>{ACTION}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!insuranceF ? (
                                                    <>{<NoDataFound />}</>
                                                ) : (
                                                    insuranceF &&
                                                    insuranceF.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{(1 - 1) * 100 + i + 1}</td>
                                                                <td>
                                                                    <div data-toggle="tooltip" title={item.fileName}>
                                                                        {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : INSURANCE_FRONT + ".png"}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                        <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                            <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                        </button>
                                                                    ) : (
                                                                        <Zoom>
                                                                            <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                        </Zoom>
                                                                    )}{" "}
                                                                </td>
                                                                <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-primary btn-xs" onClick={() => handleEdit(INSURANCE_FRONT, item.id, item.type)}>
                                                                        <i className="fa  fa-pencil"></i>
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id, item.type, patientId)}>
                                                                        <i className="fa  fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex justify-content-between my-2 mx-2">
                                        <div>
                                            <p style={{ fontSize: "Larger", fontWeight: "600" }}>{INSURANCE_BACK}</p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div>
                                                <button
                                                    className="btn btn-info mx-2"
                                                    style={{ borderRadius: "50%", width: "35px", height: "35px" }}
                                                    onClick={() => handleClick(INSURANCE_BACK, PATIENT_DOCUMENT_TYPE.INSURANCE_BACK)}
                                                >
                                                    <i className="fa fa-plus" style={{ marginLeft: "-1px" }}></i>{" "}
                                                </button>
                                            </div>
                                            <div style={{ marginTop: "10px" }}>
                                                <button onClick={() => handleWebcameClick(INSURANCE_BACK, PATIENT_DOCUMENT_TYPE.INSURANCE_BACK)}>
                                                    {" "}
                                                    <i className="fa fa-camera " style={{ fontSize: "30px" }}></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-scrollable">
                                        <table className="table table-hover table-checkable order-column full-width" id="example4">
                                            <thead>
                                                <tr>
                                                    <th>{NO}</th>
                                                    <th>{FILE_NAME}</th>
                                                    <th>{THUMBNAIL}</th>
                                                    <th>{CREATED_DATE}</th>
                                                    <th>{ACTION}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!insuranceB ? (
                                                    <>{<NoDataFound />}</>
                                                ) : (
                                                    insuranceB &&
                                                    insuranceB.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{(1 - 1) * 100 + i + 1}</td>
                                                                <td>
                                                                    <div data-toggle="tooltip" title={item.fileName}>
                                                                        {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : INSURANCE_BACK + ".png"}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                        <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                            <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                        </button>
                                                                    ) : (
                                                                        <Zoom>
                                                                            <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                        </Zoom>
                                                                    )}{" "}
                                                                </td>
                                                                <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-primary btn-xs" onClick={() => handleEdit(INSURANCE_BACK, item.id, item.type)}>
                                                                        <i className="fa  fa-pencil"></i>
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id, item.type, patientId)}>
                                                                        <i className="fa  fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex justify-content-between my-2 mx-2">
                                        <div>
                                            <p style={{ fontSize: "Larger", fontWeight: "600" }}>{XRAY}</p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div>
                                                <button
                                                    className="btn btn-info mx-2"
                                                    style={{ borderRadius: "50%", width: "35px", height: "35px" }}
                                                    onClick={() => handleClick(XRAY, PATIENT_DOCUMENT_TYPE.X_RAY)}
                                                >
                                                    <i className="fa fa-plus" style={{ marginLeft: "-1px" }}></i>{" "}
                                                </button>
                                            </div>
                                            <div style={{ marginTop: "10px" }}>
                                                <button onClick={() => handleWebcameClick(XRAY, PATIENT_DOCUMENT_TYPE.X_RAY)}>
                                                    {" "}
                                                    <i className="fa fa-camera " style={{ fontSize: "30px" }}></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-scrollable">
                                        <table className="table table-hover table-checkable order-column full-width" id="example4">
                                            <thead>
                                                <tr>
                                                    <th>{NO}</th>
                                                    <th>{FILE_NAME}</th>
                                                    <th>{THUMBNAIL}</th>
                                                    <th>{CREATED_DATE}</th>
                                                    <th>{ACTION}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!imageX ? (
                                                    <>{<NoDataFound />}</>
                                                ) : (
                                                    imageX &&
                                                    imageX.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{(1 - 1) * 100 + i + 1}</td>
                                                                <td>
                                                                    <div data-toggle="tooltip" title={item.fileName}>
                                                                        {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : XRAY + ".png"}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                        <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                            <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                        </button>
                                                                    ) : (
                                                                        <Zoom>
                                                                            <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                        </Zoom>
                                                                    )}{" "}
                                                                </td>
                                                                <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-primary btn-xs" onClick={() => handleEdit(XRAY, item.id, item.type)}>
                                                                        <i className="fa  fa-pencil"></i>
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id, item.type, patientId)}>
                                                                        <i className="fa  fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex justify-content-between my-2 mx-2">
                                        <div>
                                            <p style={{ fontSize: "Larger", fontWeight: "600" }}>{NOTE}</p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div>
                                                <button
                                                    className="btn btn-info mx-2"
                                                    style={{ borderRadius: "50%", width: "35px", height: "35px" }}
                                                    onClick={() => handleClick(NOTE, PATIENT_DOCUMENT_TYPE.NOTE)}
                                                >
                                                    <i className="fa fa-plus" style={{ marginLeft: "-1px" }}></i>{" "}
                                                </button>
                                            </div>
                                            <div style={{ marginTop: "10px" }}>
                                                <button onClick={() => handleWebcameClick(NOTE, PATIENT_DOCUMENT_TYPE.NOTE)}>
                                                    {" "}
                                                    <i className="fa fa-camera " style={{ fontSize: "30px" }}></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-scrollable">
                                        <table className="table table-hover table-checkable order-column full-width" id="example4">
                                            <thead>
                                                <tr>
                                                    <th>{NO}</th>
                                                    <th>{FILE_NAME}</th>
                                                    <th>{THUMBNAIL}</th>
                                                    <th>{CREATED_DATE}</th>
                                                    <th>{ACTION}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!imageN ? (
                                                    <>{<NoDataFound />}</>
                                                ) : (
                                                    imageN &&
                                                    imageN.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{(1 - 1) * 100 + i + 1}</td>
                                                                <td>
                                                                    <div data-toggle="tooltip" title={item.fileName}>
                                                                        {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : NOTE + ".png"}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                        <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                            <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                        </button>
                                                                    ) : (
                                                                        <Zoom>
                                                                            <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                        </Zoom>
                                                                    )}{" "}
                                                                </td>
                                                                <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-primary btn-xs" onClick={() => handleEdit(NOTE, item.id, item.type)}>
                                                                        <i className="fa  fa-pencil"></i>
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id, item.type, patientId)}>
                                                                        <i className="fa  fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex justify-content-between my-2 mx-2">
                                        <div>
                                            <p style={{ fontSize: "Larger", fontWeight: "600" }}>{ID}</p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div>
                                                <button
                                                    className="btn btn-info mx-2"
                                                    style={{ borderRadius: "50%", width: "35px", height: "35px" }}
                                                    onClick={() => handleClick(ID, PATIENT_DOCUMENT_TYPE.ID)}
                                                >
                                                    <i className="fa fa-plus" style={{ marginLeft: "-1px" }}></i>{" "}
                                                </button>
                                            </div>
                                            <div style={{ marginTop: "10px" }}>
                                                <button onClick={() => handleWebcameClick(ID, PATIENT_DOCUMENT_TYPE.ID)}>
                                                    {" "}
                                                    <i className="fa fa-camera " style={{ fontSize: "30px" }}></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-scrollable">
                                        <table className="table table-hover table-checkable order-column full-width" id="example4">
                                            <thead>
                                                <tr>
                                                    <th>{NO}</th>
                                                    <th>{FILE_NAME}</th>
                                                    <th>{THUMBNAIL}</th>
                                                    <th>{CREATED_DATE}</th>
                                                    <th>{ACTION}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!imageId ? (
                                                    <>{<NoDataFound />}</>
                                                ) : (
                                                    imageId &&
                                                    imageId.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{(1 - 1) * 100 + i + 1}</td>
                                                                <td>
                                                                    <div data-toggle="tooltip" title={item.fileName}>
                                                                        {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : ID + ".png"}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                        <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                            <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                        </button>
                                                                    ) : (
                                                                        <Zoom>
                                                                            <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                        </Zoom>
                                                                    )}{" "}
                                                                </td>
                                                                <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-primary btn-xs" onClick={() => handleEdit(ID, item.id, item.type)}>
                                                                        <i className="fa  fa-pencil"></i>
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id, item.type, patientId)}>
                                                                        <i className="fa  fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex justify-content-between my-2 mx-2">
                                        <div>
                                            <p style={{ fontSize: "Larger", fontWeight: "600" }}>{OTHERS}</p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div>
                                                <button
                                                    className="btn btn-info mx-2"
                                                    style={{ borderRadius: "50%", width: "35px", height: "35px" }}
                                                    onClick={() => handleClick(OTHERS, PATIENT_DOCUMENT_TYPE.OTHERS)}
                                                >
                                                    <i className="fa fa-plus" style={{ marginLeft: "-1px" }}></i>{" "}
                                                </button>
                                            </div>
                                            <div style={{ marginTop: "10px" }}>
                                                <button onClick={() => handleWebcameClick(OTHERS, PATIENT_DOCUMENT_TYPE.OTHERS)}>
                                                    {" "}
                                                    <i className="fa fa-camera " style={{ fontSize: "30px" }}></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-scrollable">
                                        <table className="table table-hover table-checkable order-column full-width" id="example4">
                                            <thead>
                                                <tr>
                                                    <th>{NO}</th>
                                                    <th>{FILE_NAME}</th>
                                                    <th>{THUMBNAIL}</th>
                                                    <th>{CREATED_DATE}</th>
                                                    <th>{ACTION}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!imageOt ? (
                                                    <>{<NoDataFound />}</>
                                                ) : (
                                                    imageOt &&
                                                    imageOt.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{(1 - 1) * 100 + i + 1}</td>
                                                                <td>
                                                                    <div data-toggle="tooltip" title={item.fileName}>
                                                                        {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : OTHERS + ".png"}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                        <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                            <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                        </button>
                                                                    ) : (
                                                                        <Zoom>
                                                                            <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                        </Zoom>
                                                                    )}{" "}
                                                                </td>
                                                                <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-primary btn-xs" onClick={() => handleEdit(OTHERS, item.id, item.type)}>
                                                                        <i className="fa  fa-pencil"></i>
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id, item.type, patientId)}>
                                                                        <i className="fa  fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-actions ">
                    <div className="center">
                        {isLoading ? (
                            <>
                                <button type="submit" className="btn btn-info m-r-20" disabled>
                                    {SAVE}
                                </button>
                                <i className="fa fa-spinner fa-spin fa-2x loaderBtnSpinner"></i>
                            </>
                        ) : (
                            <button type="submit" className="btn btn-info m-r-20" onClick={handleFormSubmit}>
                                {SAVE}
                            </button>
                        )}
                        <button className="btn btn-default m-r-20" onClick={btnCancel}>
                            {CANCEL}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditPatient;
