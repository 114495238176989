import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { frontURL } from "../../Config";
import {
    ADD_CLAIM,
    ALL_CLAIM,
    ALL_PATIENT,
    CLAIM,
    COMPREHENSIVE_NOTES,
    DASHBOARD,
    DOCTOR_PAGES,
    SUPPORT,
    DOCUMENT_LIST,
    ELIGIBILITY_CHECK,
    EOB,
    EOB_LIST,
    RECEIPT,
    RECEIPT_LIST,
    NOTES,
    OFFICE,
    OFFICE_LIST,
    OP_NOTES,
    PATIENT,
    ROLES,
    PRE_AUTHORIZATION_NOTES,
    OFFICE_EMPLOYEE_LIST,
    OFFICE_EMPLOYEE,
} from "../../Helper/Constants";
import Cookies from "universal-cookie";

function DoctorSidebar(props) {
    const cookies = new Cookies();
    let navigate = useNavigate();
    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }
    const logEmail = JSON.parse(localStorage.getItem("logToken")).email;

    let isTrial = cookies.get("isTrial");
    if (isTrial) {
        isTrial = cookies.get("isTrial").isTrial;
    }
    let adminRole = localStorage.getItem("adminRole");

    let pageAccess = cookies.get("doctorPageAccess") ? (cookies.get("doctorPageAccess").length ? cookies.get("doctorPageAccess") : [14, 15, 16, 17, 18, 19, 20]) : "";

    const claimPagination = sessionStorage.getItem("claimPagination");
    const opNotePagination = sessionStorage.getItem("opNotePagination");
    const cnPagination = sessionStorage.getItem("cnPagination");
    const preAuthPagination = sessionStorage.getItem("preAuthPagination");
    const preAuthDoctorId = sessionStorage.getItem("preAuthDoctorId");
    const patientPagination = sessionStorage.getItem("patientPagination");
    const officePagination = sessionStorage.getItem("officePagination");
    const eobPagination = sessionStorage.getItem("eobPagination");
    const receiptPagination = sessionStorage.getItem("receiptPagination");

    const doctorPagination = sessionStorage.getItem("doctorPagination");

    const handleDoctorList = () => {
        if (doctorPagination) {
            sessionStorage.removeItem("doctorPagination");
            navigate("/manageDoctor");
            window.location.reload();
        }
    };

    const handleEOBList = () => {
        if (eobPagination) {
            sessionStorage.removeItem("eobPagination");
            navigate("/manageEOB");
            window.location.reload();
        }
    };

    const handleReceiptList = () => {
        if (receiptPagination) {
            sessionStorage.removeItem("receiptPagination");
            navigate("/manageReceipt");
            window.location.reload();
        }
    };

    const handleOfficeList = () => {
        if (officePagination) {
            sessionStorage.removeItem("officePagination");
            navigate("/manageOffice");
            window.location.reload();
        }
    };

    const handlePreAuthorizationList = () => {
        if (preAuthPagination || preAuthDoctorId) {
            sessionStorage.removeItem("preAuthPagination");
            sessionStorage.removeItem("preAuthDoctorId");
            navigate("/manage-preAuthorization-notes");
            window.location.reload();
        }
    };

    const handleClaimList = () => {
        if (claimPagination) {
            sessionStorage.removeItem("claimPagination");
            navigate("/manageClaim");
            window.location.reload();
        }
    };

    const handleOpNotesList = () => {
        if (opNotePagination) {
            sessionStorage.removeItem("opNotePagination");
            navigate("/manageOpNotes");
            window.location.reload();
        }
    };

    const handleCnList = () => {
        if (cnPagination) {
            sessionStorage.removeItem("cnPagination");
            navigate("/ManageComprehensive");
            window.location.reload();
        }
    };

    const handlePatientList = () => {
        if (patientPagination) {
            sessionStorage.removeItem("patientPagination");
            navigate("/managePatient");
            window.location.reload();
        }
    };

    return (
        <>
            <div className="sidebar-container">
                <div className="sidemenu-container navbar-collapse collapse fixed-menu">
                    <div id="remove-scroll" className="left-sidemenu">
                        <ul className="sidemenu  page-header-fixed slimscroll-style" data-keep-expanded="false" data-auto-scroll="true" data-slide-speed="200" style={{ paddingTop: "20px" }}>
                            <li className="sidebar-toggler-wrapper hide">
                                <div className="sidebar-toggler">
                                    <span></span>
                                </div>
                            </li>
                            <li className="sidebar-user-panel">
                                <div className="user-panel">
                                    <div className="pull-left image">
                                        {props.image ? (
                                            <img
                                                src={props.image ? `${frontURL}/${props.image}` : "assets/img/dp.jpg"}
                                                className="img-circle user-img-circle"
                                                alt="User Image"
                                                style={{ height: "75px", width: "85px" }}
                                            />
                                        ) : (
                                            <img src="assets/img/logo/login-logo.png" className="img-circle user-img-circle" alt="User Image" style={{ height: "75px", width: "85px" }} />
                                        )}
                                    </div>
                                    <div className="pull-left info">
                                        <span>
                                            {" "}
                                            {isTrial == 2 ? "" : "Dr."}
                                            {props.doctorFirstName} <br />
                                            {props.doctorLastName}
                                        </span>
                                    </div>
                                </div>
                            </li>
                            {(pageAccess.includes(DOCTOR_PAGES.Dashboard) && (role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR)) || isTrial == 0 ? (
                                <li className="nav-item ">
                                    <NavLink to="/dashboard" className="nav-link">
                                        <i className="material-icons">dashboard</i>
                                        <span className="title">{DASHBOARD}</span>
                                    </NavLink>
                                </li>
                            ) : (
                                ""
                            )}
                            {role == ROLES.DOCTOR && isTrial == 0 ? (
                                <li className="nav-item">
                                    <a href="#" className="nav-link nav-toggle">
                                        {" "}
                                        <i className="material-icons">person</i>
                                        <span className="title">{OFFICE}</span> <span className="arrow"></span>
                                    </a>
                                    <ul className="sub-menu">
                                        <li className="nav-item">
                                            <NavLink to="/manageOffice" className="nav-link nav-toggle" onClick={handleOfficeList}>
                                                <span className="title">{OFFICE_LIST}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : (
                                ""
                            )}
                            {((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && pageAccess.includes(DOCTOR_PAGES.OfficeEmployee)) || isTrial == 0 ? (
                                <li className="nav-item">
                                    <a href="#" className="nav-link nav-toggle">
                                        {" "}
                                        <i className="fa fa-users"></i>
                                        <span className="title">{OFFICE_EMPLOYEE}</span> <span className="arrow"></span>
                                    </a>
                                    <ul className="sub-menu">
                                        <li className="nav-item">
                                            <NavLink to="/manageOfficeEmployee" className="nav-link nav-toggle">
                                                <span className="title">{OFFICE_EMPLOYEE_LIST}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : (
                                ""
                            )}
                            {((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && pageAccess.includes(DOCTOR_PAGES.Patient)) || isTrial == 0 ? (
                                <li className="nav-item  ">
                                    <NavLink to="#" className="nav-link nav-toggle">
                                        <i className="material-icons">accessible</i>
                                        <span className="title">{PATIENT}</span> <span className="arrow"></span>
                                    </NavLink>
                                    <ul className="sub-menu">
                                        <li className="nav-item  ">
                                            <NavLink to="/managePatient" className="nav-link " onClick={handlePatientList}>
                                                {" "}
                                                <span className="title">{ALL_PATIENT}</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item  ">
                                            <NavLink to="/checkPatientEligibility" className="nav-link ">
                                                {" "}
                                                <span className="title">{ELIGIBILITY_CHECK}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : (
                                ""
                            )}
                            {((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && pageAccess.includes(DOCTOR_PAGES.Claim)) || isTrial == 0 ? (
                                <li className="nav-item  ">
                                    <NavLink to="#" className="nav-link nav-toggle">
                                        {" "}
                                        <i className="fa fa-id-card"></i>
                                        <span className="title">{CLAIM}</span> <span className="arrow"></span>
                                    </NavLink>
                                    <ul className="sub-menu">
                                        <li className="nav-item  ">
                                            <NavLink to="/manageClaim" className="nav-link" onClick={handleClaimList}>
                                                {" "}
                                                <span className="title">{ALL_CLAIM}</span>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item  ">
                                            <NavLink to="/addClaim" className="nav-link">
                                                {" "}
                                                <span className="title">{ADD_CLAIM}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : (
                                ""
                            )}
                            {((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && pageAccess.includes(DOCTOR_PAGES.EOB)) || isTrial == 0 ? (
                                <li className="nav-item">
                                    <a className="nav-link nav-toggle">
                                        {" "}
                                        <i className="fa fa-th-list" aria-hidden="true"></i>
                                        <span className="title">{EOB}</span> <span className="arrow"></span>
                                    </a>
                                    <ul className="sub-menu">
                                        <li className="nav-item">
                                            <NavLink to="/manageEOB" className="nav-link nav-toggle" onClick={handleEOBList}>
                                                <span className="title">{EOB_LIST}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : (
                                ""
                            )}
                            {((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && pageAccess.includes(DOCTOR_PAGES.Notes)) || isTrial == 0 ? (
                                <li className="nav-item">
                                    <a className="nav-link nav-toggle">
                                        {" "}
                                        <i className="fa fa-sticky-note" aria-hidden="true"></i>
                                        <span className="title">{NOTES}</span> <span className="arrow"></span>
                                    </a>
                                    <ul className="sub-menu">
                                        <li className="nav-item">
                                            <NavLink to="/manage-preAuthorization-notes" className="nav-link nav-toggle" onClick={handlePreAuthorizationList}>
                                                <span className="title">{PRE_AUTHORIZATION_NOTES}</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/ManageComprehensive" className="nav-link nav-toggle" onClick={handleCnList}>
                                                <span className="title">{COMPREHENSIVE_NOTES}</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/manageOpNotes" className="nav-link nav-toggle" onClick={handleOpNotesList}>
                                                <span className="title">{OP_NOTES}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : (
                                ""
                            )}
                            {((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && pageAccess.includes(DOCTOR_PAGES.Documents)) || isTrial == 0 ? (
                                <li className="nav-item">
                                    <a className="nav-link nav-toggle">
                                        {" "}
                                        <i className="fa fa-file-text" aria-hidden="true"></i>
                                        <span className="title">{SUPPORT}</span> <span className="arrow"></span>
                                    </a>
                                    <ul className="sub-menu">
                                        <li className="nav-item">
                                            <NavLink to="/manageDocument" className="nav-link nav-toggle">
                                                <span className="title">{DOCUMENT_LIST}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : (
                                ""
                            )}
                            {((role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && pageAccess.includes(DOCTOR_PAGES.Receipt) && logEmail !== "youngmo@gpointwallet.com") || (isTrial == 0 && logEmail !== "youngmo@gpointwallet.com") ? (
                                <li className="nav-item">
                                    <a className="nav-link nav-toggle">
                                        {" "}
                                        <i className="fa fa-th-list" aria-hidden="true"></i>
                                        <span className="title">{RECEIPT}</span> <span className="arrow"></span>
                                    </a>
                                    <ul className="sub-menu">
                                        <li className="nav-item">
                                            <NavLink to="/manageReceipt" className="nav-link nav-toggle" onClick={handleReceiptList}>
                                                <span className="title">{RECEIPT_LIST}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : (
                                ""
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DoctorSidebar;
